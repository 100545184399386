<template>
  <Product />
</template>

<script>
// @ is an alias to /src
import Product from '@/components/Product.vue'
export default {
  name: 'CsProduct',
  components: {
    Product
  }
}
</script>
