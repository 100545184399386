<template>
  <div class="container">
    <!-- <testcp v-if="pccentenshow" class="pcview"></testcp> -->
    <!-- <GPTMenu></GPTMenu> -->
    <div v-if="pccentenshow" class="pcview">
      <el-row class="navbar navbar-default">
        <el-col :span="12">
          <el-row style="display: flex; align-items: center; margin-left: 15px">
            <img src="../assets/cslogo.png" width="430px" height="50px" />
          </el-row>
        </el-col>
        <el-col :span="12">
          <div
            class="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
            ref="navbar1"
            v-if="nvabar1show"
          >
            <el-row class="navright">
              <el-col :span="4" class="nav_item">
                <router-link to="/" class="navwz">首页</router-link>
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/CsIntroduce" class="navwz"
                  >公司介绍</router-link
                >
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/Product" class="navwz">产品领域</router-link>
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/Project" class="navwz">项目案例</router-link>
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/Contact" class="navwz">联系我们</router-link>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <!-- <el-row>
        <div
          class="collapse navbar-collapse"
          id="bs-example-navbar-collapse-2"
          ref="navbar2"
          v-if="nvabar2show"
        >
          <ul class="nav navbar-nav">
            <li><router-link to="/" class="navwz">首页</router-link></li>
            <li>
              <router-link to="/CsIntroduce" class="navwz"
                >公司介绍</router-link
              >
            </li>
            <li>
              <router-link to="/Product" class="navwz">产品领域</router-link>
            </li>
            <li>
              <router-link to="/Project" class="navwz">项目案例</router-link>
            </li>
            <li>
              <router-link to="/Contact" class="navwz">联系我们</router-link>
            </li>
          </ul>
        </div>
      </el-row> -->
      <el-row>
        <div class="shouyebg">
          <img src="../assets/shouyebg1.jpg" width="100%" />
        </div>
      </el-row>
      <el-row>
        <div style="height=20px"></div>
      </el-row>
      <el-row class="aboutbg">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row>
              <el-col :span="24">
                <div style="text-align: center; margin-top: 30px">
                  <div class="bt">公司简介</div>
                  <div class="fbt">company profile</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 3%"></el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row
              :gutter="30"
              justify="space-around"
              type="flex"
              align="middle"
            >
              <el-col :span="12">
                <div class="wenben">
                  <span style="font-weight: bolder; font-size: 30px">仓实</span
                  >信息科技有限公司是一家致力于推动空间地理技术服务的科技型企业，为国家科技部科技型中小入库企业的一员，拥有多项发明专利及行业产品著作权，连续多年获得“广东省守合同重信用企业”的认定，2022年被评为国家高新技术企业。
                </div>
                <div class="wenben">
                  公司专业从事政府规划、自然资源、住房建设部门、房地产企业、国有资产管理企业等相关单位的信息化建设咨询、应用平台构建、数据资源挖掘与分析及业务过程监理。先后为多个政府单位、企业提供关于城市更新、不动产登记、房屋安全鉴定、物业管理、空间安防、乡村振兴等信息化服务，多次获得用户好评。
                </div>
                <div class="wenben">
                  公司具有高效的内部管理制度。优良的工作环境以及良好的激励机制，吸引了一批有学识的、具有实干精神的专业人才。高素质、高水平、高效率的服务宗旨让仓实信息在当下科技型企业中立于领先地位。
                </div>
                <div class="btn_dj">
                  <router-link to="/CsIntroduce">
                    <img
                      src="../assets/tm_1xs.png"
                      width="100%"
                      height="100%"
                    />
                  </router-link>
                </div>
              </el-col>
              <el-col :span="12">
                <el-row style="padding-top: 10px">
                  <img
                    src="../assets/about_1.jpg"
                    width="100%"
                    height="400px"
                    style="border-radius: 10px"
                  />
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 3%"></el-row>
      </el-row>
      <!--核心业务-->
      <el-row class="yewubg">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row>
              <el-col :span="24">
                <div style="text-align: center; margin-top: 30px">
                  <div class="bt" id="yewu">核心业务</div>
                  <div class="fbt">business core</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="1" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="22" :xl="16">
            <el-row style="margin-top: 60px" :gutter="30">
              <el-col :span="8">
                <div class="yewe_gyk1">
                  <a>电子政务平台开发与建设</a>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="yewe_gyk2">
                  <a>企业资源管理平台开发建设</a>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="yewe_gyk3">
                  <a>信息系统平台建设咨询</a>
                </div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 60px" :gutter="30">
              <el-col :span="8">
                <div class="yewe_gyk4">
                  <a>数据规范化整理建库</a>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="yewe_gyk5">
                  <a>数据挖掘与咨询分析</a>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="yewe_gyk6">
                  <a>档案规范化与扫描</a>
                </div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 60px" :gutter="30">
              <el-col :span="8">
                <div class="yewe_gyk7">
                  <a>土地、房产测绘生产</a>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="yewe_gyk8">
                  <a>工程造价评估</a>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="yewe_gyk9">
                  <a>业务过程监理</a>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="1" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <!-- 下面忽略 -->
        <!-- <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="4" :xl="4">
            <div class="grid-content bg-purple-light">
              <el-row>
                <el-col :span="1">
                  <div class="grid-content bg-purple">
                    <div class="nbtzx"></div>
                  </div>
                </el-col>
                <el-col :span="23">
                  <div class="grid-content bg-purple-light">
                    <el-row>
                      <div class="nbt">核心业务</div>
                    </el-row>
                    <el-row>
                      <P class="nbtp">core business</P>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="16" :xl="16">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row> -->
        <el-row style="margin-top: 5%"></el-row>
      </el-row>
      <!-- 公司资质 -->
      <el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row>
              <el-col :span="24">
                <div style="text-align: center; margin-top: 30px">
                  <div class="bt" id="zizhi">公司资质</div>
                  <div class="fbt">Company qualification</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 3%"></el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="1" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="22" :xl="16">
            <el-row :gutter="20" style="text-align: center">
              <el-col :span="8">
                <el-row>
                  <img src="../assets/zizhi_1.png" width="21%" />
                  <div class="zizhi-title">国家高新技术企业</div>
                  <div class="zizhi-content">
                    指在《国家重点支持的高新技术领域》内，持续进行研究开发与技术成果转化，形成企业核心自主知识产权，并以此为基础开展经营活动
                  </div>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <img src="../assets/zizhi_2.png" width="21%" />
                  <div class="zizhi-title">国家科技部科技型中小入库企业</div>
                  <div class="zizhi-content">
                    指在企业里有一定数量的科技人员从事科学技术研究开发活动，并且取得自主知识产权将其转化为高新技术产品或服务，从而实现可持续发展的中小企业
                  </div>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <img src="../assets/zizhi_3.png" width="21%" />
                  <div class="zizhi-title">广东省“守合同重信用”企业</div>
                  <div class="zizhi-content">
                    指在广东省内注册经营两年以上，从未出现不良现象，合同管理制度健全，能全面履行合同，合同履约率达100%，经工商部门认定并向外界公示信誉口碑极好的企业
                  </div>
                </el-row>
              </el-col>
            </el-row>
            <el-row style="margin-top: 3%"></el-row>

            <el-row :gutter="30" style="text-align: center">
              <el-col :span="8">
                <el-row>
                  <img src="../assets/zizhi_4.png" width="21%" />
                  <div class="zizhi-title">中国政府采购信用企业</div>
                  <div class="zizhi-content">
                    指经综合评价,信用等级在A级以上的企业为"信用企业"，信用依据企业各项能力、情况、管理行为以及企业历史信用等全面对企业信用情况进行大数据分析评价
                  </div>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <img src="../assets/zizhi_5.png" width="21%" />
                  <div class="zizhi-title">信用中国AAA认证单位</div>
                  <div class="zizhi-content">
                    指中国各银行对企业信用等级评价中，得分最高的企业。其信用程度高，合同履约能力强。该类企业具有优秀的信用纪录，经营状况佳，盈利能力强，发展前景广阔
                  </div>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <img src="../assets/zizhi_6.png" width="21%" />
                  <div class="zizhi-title">A级纳税信用认证单位</div>
                  <div class="zizhi-content">
                    指税务机关通过对纳税人连续两年的税务纳税情况以及相关行政执法部门对纳税人社会诚信的评价等项目进行综合评定，纳税信誉良好的纳税人
                  </div>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="1" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row>
      </el-row>
      <el-row style="margin-top: 3%"></el-row>
      <!-- <el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row>
              <el-col :span="24">
                <div style="text-align: center; margin-top: 30px">
                  <div class="bt">公司版权专利</div>
                  <div class="fbt">Company copyright&patent</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
      </el-row> -->

      <!-- 战略合作 -->
      <el-row class="hezbg">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row>
              <el-col :span="24">
                <div style="text-align: center; margin-top: 30px">
                  <div class="bt">合作伙伴</div>
                  <div class="fbt">cooperative partner</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <!--战略合作图片-->
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <!-- <el-row class="cooperation-title"> 重点服务政务部门 </el-row> -->
            <!-- <el-row :gutter="20" style="margin-top: 20px">
              <el-col :span="8">
                <img src="../assets/logo_1.jpg" width="100%" height="100%" />
              </el-col>
              <el-col :span="8">
                <img src="../assets/logo_2.jpg" width="100%" height="100%" />
              </el-col>
              <el-col :span="8">
                <img src="../assets/logo_3.jpg" width="100%" height="100%" />
              </el-col>
            </el-row> -->
            <el-row style="margin-top: 20px">
              <el-col :span="4">
                <img
                  src="../assets/logo_4.jpg"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <el-col :span="4">
                <img
                  src="../assets/logo_10.png"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <el-col :span="4">
                <img
                  src="../assets/logo_8.jpg"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <el-col :span="4">
                <img
                  src="../assets/logo_7.png"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <el-col :span="4">
                <img
                  src="../assets/logo_2.png"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <el-col :span="4">
                <img
                  src="../assets/logo_3.png"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <img
                  src="../assets/logo_9.png"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <el-col :span="4">
                <img
                  src="../assets/logo_1.jpg"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <el-col :span="4">
                <img
                  src="../assets/logo_5.png"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <el-col :span="4">
                <img
                  src="../assets/logo_6.png"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <el-col :span="4">
                <img
                  src="../assets/logo_13.png"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <!-- <el-col :span="4">
                <img
                  src="../assets/logo_14.png"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col> -->
            </el-row>
            <el-row>
              <el-col :span="4">
                <img
                  src="../assets/logo_14.png"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <el-col :span="4">
                <img
                  src="../assets/logo_12.jpg"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <el-col :span="4">
                <img
                  src="../assets/logo_17.png"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
              <el-col :span="4">
                <img
                  src="../assets/logo_16.png"
                  width="100%"
                  style="border: 1px solid #eeeeee; background-color: #ffffff"
                />
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 60px"></el-row>
      </el-row>
      <!-- 底部 -->
      <div class="foot">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="20">
            <el-row style="margin-top: 30px"></el-row>
            <el-row style="display: flex; align-items: center">
              <el-col :xs="4" :sm="4" :md="3" :lg="3" :xl="3">
                <img src="../assets/logo-foot.png" width="100%" />
              </el-col>
              <el-col :xs="20" :sm="20" :md="21" :lg="21" :xl="21">
                <el-row style="margin-left: 150px">
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">公司介绍</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpabout">关于仓实</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpcourse">
                          公司历程
                        </div>
                      </el-row>
                      <el-row>
                        <a class="aboutitem" href="#yewu">核心业务</a>
                      </el-row>
                      <el-row>
                        <a class="aboutitem" href="#zizhi">公司资质</a>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpculture">
                          企业文化
                        </div>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">公司产品</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpgov">
                          政府信息化应用平台
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpgov">
                          政府行业技术服务
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpent">
                          企业信息化应用平台
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpent">
                          企业行业技术服务
                        </div>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">项目案例</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumphejin">合景泰富</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpbudongchan">
                          不动产信息建设
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpshidai">
                          时代中国
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpkaide">凯德中国</div>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">联系我们</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb1.png"
                          width="20px"
                          height="18px"
                        />
                        <div class="aboutitem">020-89884234</div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb2.png"
                          width="20px"
                          height="20px"
                        />
                        <div class="aboutitem">
                          zhongweiming@cangshigroup.com
                        </div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb3.png"
                          width="20px"
                          height="16px"
                        />
                        <div class="aboutitem">邮政编码:510000</div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb4.png"
                          width="20px"
                          height="21px"
                        />
                        <div class="aboutitem">
                          广州市越秀区广卫路19-2金科大厦6楼
                        </div>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-top: 20px"></div>
        </el-row>
        <el-row>
          <div
            style="
              text-align: center;
              color: #ccc;
              line-height: 30px;
              font-size: 14px;
              font-weight: lighter;
              border-top: 1px solid #222634;
            "
          >
            CopyRight © 2022 All Right Reserved 广州仓实信息科技有限公司
            粤ICP备07032789号
          </div>
        </el-row>
      </div>
      <back-top />
      <el-backtop
        target=".container"
        :bottom="30"
        :right="50"
        :visibility-height="400"
      >
        <div
          style="
             {
              height: 100%;
              width: 100%;
              background-color: #ff8041;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
              text-align: center;
              line-height: 40px;
              color: #ffffff;
              border-radius: 50px;
              align-items: center;
              text-align: center;
            }
          "
        >
          <i class="el-icon-caret-top"></i>
        </div>
      </el-backtop>
    </div>
    <!--移动端-->
    <div class="mobileshow" v-if="mobilecentenshow" id="11">
      <el-row
        style="display: flex; align-items: center; padding: 10px 10px 10px 10px"
      >
        <el-col :span="20">
          <div @click="backhome">
            <img src="../assets/cslogo-mb.png" style="width: 100%" />
          </div>
        </el-col>
        <el-col :span="4" style="text-align: end">
          <div @click="hangdleclick = true">
            <img src="../assets/menu_b.png" style="width: 50%" />
          </div>
        </el-col>
      </el-row>
      <!-- <nav style="padding: 15px 0 15px 0">
        <div style="padding-left: 10px">
          <el-row style="align-items: center; display: flex">


          </el-row>
        </div>
      </nav> -->

      <el-drawer
        title=""
        :visible.sync="hangdleclick"
        :direction="direction"
        size="60%"
        :overflow-y="hidden"
      >
        <el-row>
          <el-menu default-active="/" router active-text-color="#ff8041">
            <el-menu-item index="/">
              <template slot="title">
                <div
                  @click="hangdleclick = false"
                  style="
                    background-color: #eeeeee;
                    border-radius: 10px;
                    padding: 0 0 0 10px;
                  "
                >
                  <i class="el-icon-house" style="font-weight: bolder"></i>
                  <span class="navtb">首页</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/CsIntroduce">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i
                    class="el-icon-office-building"
                    style="font-weight: bolder"
                  ></i>
                  <span class="navtb">公司介绍</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/Product">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i class="el-icon-box" style="font-weight: bolder"></i>
                  <span class="navtb">产品领域</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/Project">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i
                    class="el-icon-data-analysis"
                    style="font-weight: bolder"
                  ></i>
                  <span class="navtb">项目案例</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/Contact">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i class="el-icon-message" style="font-weight: bolder"></i>
                  <span class="navtb">联系我们</span>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-row>
      </el-drawer>

      <el-row>
        <img src="../assets/banner.jpg" width="100%" />
      </el-row>
      <!-- 公司简介 -->
      <el-row>
        <el-col :span="24">
          <div class="tltle">
            <p class="ntz">公司简介</p>
            <p class="nxd">company profile</p>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple-light"></div>
        </el-col>
        <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
          <div class="aboutwz">
            仓实信息科技有限公司是一家致力于推动空间地理技术服务的科技型企业，为国家科技部科技型中小入库企业的一员，拥有多项发明专利及行业产品著作权，连续多年获得“广东省守合同重信用企业”的认定，2022年被评为国家高新技术企业。
          </div>
          <div class="aboutwz">
            公司专业从事政府规划、自然资源、住房建设部门、房地产企业、国有资产管理企业等相关单位的信息化建设咨询、应用平台构建、数据资源挖掘与分析及业务过程监理。先后为多个政府单位、企业提供关于<span
              style="font-weight: bolder"
              >城市更新、不动产登记、物管安防，乡村振兴，空间数据资源建库</span
            >等地理信息系统的开发及数据处理服务，多次获得用户好评。
          </div>
          <div style="text-align: center; margin-top: 3%">
            <el-button plain @click="FindMore">了解更多</el-button>
          </div>
        </el-col>
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple"></div>
        </el-col>
      </el-row>
      <el-row>
        <div style="margin-top: 20px"></div>
      </el-row>
      <el-row class="bgcolor">
        <el-col :span="24">
          <div class="tltle">
            <p class="ntz">核心业务</p>
            <p class="nxd">core business</p>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bgcolor">
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple"></div>
        </el-col>
        <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
          <el-row>
            <el-col :span="24">
              <el-row style="text-align: center">
                <img src="../assets/yewu_1.png" width="15%" height="15%" />
                <div class="yewumbbt">电子政务平台开发与建设</div>
                <div class="yewumbwz">E-govemment platform</div>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="margin-top: 7%"></el-row>
          <el-row>
            <el-col :span="24">
              <el-row style="text-align: center">
                <img src="../assets/yewu_2.png" width="15%" height="15%" />
                <div class="yewumbbt">企业资源管理平台开发建设</div>
                <div class="yewumbwz">
                  Enterprise resource management platform
                </div>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="margin-top: 7%"></el-row>
          <el-row>
            <el-col :span="24">
              <el-row style="text-align: center">
                <img src="../assets/yewu_3.png" width="15%" height="15%" />
                <div class="yewumbbt">信息系统平台建设咨询</div>
                <div class="yewumbwz">Information system platform</div>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="margin-top: 7%"></el-row>
          <el-row>
            <el-col :span="24">
              <el-row style="text-align: center">
                <img src="../assets/yewu_4.png" width="15%" height="15%" />
                <div class="yewumbbt">数据规范化整理建库</div>
                <div class="yewumbwz">Data sorting and database building</div>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="margin-top: 7%"></el-row>
          <el-row>
            <el-col :span="24">
              <el-row style="text-align: center">
                <img src="../assets/yewu_5.png" width="15%" height="15%" />
                <div class="yewumbbt">数据挖掘与咨询分析</div>
                <div class="yewumbwz">Data mining analysis</div>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="margin-top: 7%"></el-row>
          <el-row>
            <el-col :span="24">
              <el-row style="text-align: center">
                <img src="../assets/yewu_6.png" width="15%" height="15%" />
                <div class="yewumbbt">档案规范化与扫描</div>
                <div class="yewumbwz">
                  Standardization and scanning of archives
                </div>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="margin-top: 7%"></el-row>
          <el-row>
            <el-col :span="24">
              <el-row style="text-align: center">
                <img src="../assets/yewu_7.png" width="15%" height="15%" />
                <div class="yewumbbt">土地、房产测绘生产</div>
                <div class="yewumbwz">
                  Land and property surveying and mapping production
                </div>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="margin-top: 7%"></el-row>
          <el-row>
            <el-col :span="24">
              <el-row style="text-align: center">
                <img src="../assets/yewu_8.png" width="15%" height="15%" />
                <div class="yewumbbt">工程造价评估</div>
                <div class="yewumbwz">Project cost evaluation</div>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="margin-top: 7%"></el-row>
          <el-row>
            <el-col :span="24">
              <el-row style="text-align: center">
                <img src="../assets/yewu_9.png" width="15%" height="15%" />
                <div class="yewumbbt">业务过程监理</div>
                <div class="yewumbwz">Business process supervision</div>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10%"></el-row>
        </el-col>
      </el-row>
      <!--移动端 公司资质-->
      <el-row>
        <img src="../assets/chengjiu.jpg" width="100%" />
      </el-row>

      <el-row style="background-color: #f7f7f7">
        <el-col :span="24">
          <div class="tltle">
            <p class="ntz">公司资质</p>
            <p class="nxd">COMPANY QUALIFICATION</p>
          </div>
        </el-col>
      </el-row>
      <el-row style="background-color: #f7f7f7">
        <el-row style="margin-top: 5%"></el-row>
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple-light"></div>
        </el-col>
        <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
          <el-row>
            <el-col :span="24">
              <div style="text-align: center">
                <img src="../assets/zizhi1.png" width="15%" height="15%" />
                <div class="zizhibt">国家高新技术企业</div>
                <div class="zizhiwz">
                  指在《国家重点支持的高新技术领域》内，持续进行研究开发与技术成果转化，形成企业核心自主知识产权，并以此为基础开展经营活动
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10%"></el-row>
          <el-row>
            <el-col :span="24">
              <div style="text-align: center">
                <img src="../assets/zizhi2.png" width="15%" height="15%" />
                <div class="zizhibt">国家科技部科技型中小入库企业</div>
                <div class="zizhiwz">
                  指在企业里有一定数量的科技人员从事科学技术研究开发活动，并且取得自主知识产权将其转化为高新技术产品或服务，从而实现可持续发展的中小企业
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10%"></el-row>
          <el-row>
            <el-col :span="24">
              <div style="text-align: center">
                <img src="../assets/zizhi3.png" width="15%" height="15%" />
                <div class="zizhibt">广东省“守合同重信用”企业</div>
                <div class="zizhiwz">
                  指在广东省内注册经营两年以上，从未出现不良现象，合同管理制度健全，能全面履行合同，合同履约率达100%，经工商部门认定并向外界公示信誉口碑极好的企业
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10%"></el-row>
          <el-row>
            <el-col :span="24">
              <div style="text-align: center">
                <img src="../assets/zizhi4.png" width="15%" height="15%" />
                <div class="zizhibt">中国政府采购信用企业</div>
                <div class="zizhiwz">
                  指经综合评价，信用等级在A级以上企业为“信用企业”，信用依据企业各项能力、情况、管理行为以及企业历史信用等全面对企业信用情况进行大数据分析评价
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10%"></el-row>
          <el-row>
            <el-col :span="24">
              <div style="text-align: center">
                <img src="../assets/zizhi5.png" width="15%" height="15%" />
                <div class="zizhibt">信用中国AAA认证单位</div>
                <div class="zizhiwz">
                  指中国各银行对企业信用等级评价中，得分最高的企业。其信用程度高，合同履约能力强。该类企业具有优秀的信用纪录，经营状况佳，盈利能力强，发展前景广阔
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10%"></el-row>
          <el-row>
            <el-col :span="24">
              <div style="text-align: center">
                <img src="../assets/zizhi6.png" width="15%" height="15%" />
                <div class="zizhibt">A级纳税信用认证单位</div>
                <div class="zizhiwz">
                  指税务机关通过对纳税人连续两年的税务纳税情况以及相关行政执法部门对纳税人社会诚信的评价等项目进行综合评定，纳税信誉良好的纳税人
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10%"></el-row>
        </el-col>
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple-light"></div>
        </el-col>
      </el-row>

      <!-- <el-row>
        <el-col :span="24">
          <div class="tltle">
            <p class="ntz">公司版权专利</p>
            <p class="nxd">company copyright&patent</p>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple-light"></div>
        </el-col>
        <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
          <el-row>
            <el-col :span="24" style="text-align: center">
              <div
                style="
                  background-color: #222634;
                  border: 1px solid #eeeeee;
                  color: #ffffff;
                  border-radius: 100%;
                  height: 100%;
                  padding: 20%;
                "
              >
                <div>城市更新系列产品</div>
                <div>房屋安全鉴定信息管理平台</div>
                <div>房屋安全管理信息平台</div>
                <div>WebGis综合地图应用系统</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="text-align: center">
              <div
                style="
                  background-color: #222634;
                  border: 1px solid #eeeeee;
                  color: #ffffff;
                  border-radius: 100%;
                  height: 100%;
                  padding: 20%;
                "
              >
                <div>进度信息处理方法</div>
                <div>处理测绘信息方法</div>
                <div>补偿协议生成方法</div>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple-light"></div>
        </el-col>
      </el-row> -->

      <!--移动端 战略合作-->
      <el-row class="biaoti" style="background-color: #fff">
        <el-col :span="24">
          <div class="tltle">
            <p class="ntz">合作伙伴</p>
            <p class="nxd">cooperative partner</p>
          </div>
        </el-col>
      </el-row>

      <el-row style="background-color: #fff; padding-bottom: 10px">
        <el-row style="margin-top: 5%"></el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="3" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="18" :xl="16">
            <el-row justify="center" type="flex">
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_4.png" width="100%" />
                </div>
              </el-col>
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_10.png" width="100%" />
                </div>
              </el-col>
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_8.png" width="100%" />
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="3" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="3" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="18" :xl="16">
            <el-row justify="center" type="flex">
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_7.png" width="100%" />
                </div>
              </el-col>
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_2.png" width="100%" />
                </div>
              </el-col>
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_3.png" width="100%" />
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="3" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="3" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="18" :xl="16">
            <el-row justify="center" type="flex">
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_9.png" width="100%" />
                </div>
              </el-col>
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_1.png" width="100%" />
                </div>
              </el-col>
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_5.png" width="100%" />
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="3" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="3" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="18" :xl="16">
            <el-row justify="center" type="flex">
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_6.png" width="100%" />
                </div>
              </el-col>
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_13.png" width="100%" />
                </div>
              </el-col>
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_14.png" width="100%" />
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="3" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="3" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="18" :xl="16">
            <el-row justify="center" type="flex">
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_12.png" width="100%" />
                </div>
              </el-col>
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_17.png" width="100%" />
                </div>
              </el-col>
              <el-col :span="8" style="border: 1px solid #eeeeee">
                <div>
                  <img src="../assets/hz_logo_16.png" width="100%" />
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="3" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5%"></el-row>
      </el-row>

      <!-- <GPTMenu></GPTMenu> -->
      <!-- 移动端底部导航栏 -->
      <div style="background-color: #1b2033">
        <el-row>
          <el-col :xs="2" :sm="2" :md="4" :lg="4" :xl="5">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="20" :sm="20" :md="16" :lg="16" :xl="14">
            <el-row style="margin-top: 30px">
              <img src="../assets/logo-footmb.png" width="50%" />
            </el-row>
            <el-row style="margin-top: 30px">
              <el-col :span="10">
                <router-link to="/CsIntroduce" class="footwz_mb"
                  >关于仓实</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
              <el-col :span="14">
                <router-link to="/Product" class="footwz_mb"
                  >公司产品</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 20px">
              <el-col :span="10">
                <router-link to="/Project" class="footwz_mb"
                  >项目案例</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
              <el-col :span="14">
                <router-link to="/Contact" class="footwz_mb"
                  >联系我们</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row
              style="color: #a6a7a9; font-size: 12px; font-weight: lighter"
            >
              <el-row> CopyRight © 2022 All Right Reserved </el-row>
              <el-row> 广州仓实信息科技有限公司 </el-row>
              <el-row> 粤ICP备07032789号 </el-row>
            </el-row>
            <!-- <el-row type="flex" justify="center" class="footnav">
              <el-col :span="6" style="border-right: 1px solid #a6a7a9">
                <router-link to="/">首页</router-link>
              </el-col>
              <el-col :span="6" style="border-right: 1px solid #a6a7a9">
                <router-link to="/CsIntroduce">公司介绍</router-link>
              </el-col>
              <el-col :span="6" style="border-right: 1px solid #a6a7a9">
                <router-link to="/Project">项目案例</router-link>
              </el-col>
              <el-col :span="6">
                <router-link to="/Contact">联系我们</router-link>
              </el-col>
            </el-row> -->
            <el-row>
              <div style="margin-top: 3%"></div>
            </el-row>
          </el-col>
          <el-col :xs="2" :sm="2" :md="4" :lg="4" :xl="5">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>

        <!-- <el-row justify="center" type="flex">
          <el-col :span="5">
            <div style="width: 20px"></div>
          </el-col>
          <el-col :span="14">
            <div class="banqz">
              CopyRight 2022 All Right Reserved <br />广州仓实信息科技有限公司
              粤ICP备07032789号
            </div>
          </el-col>
          <el-col :span="5">
            <div style="width: 20px"></div>
          </el-col>
        </el-row> -->
        <el-row>
          <div style="margin-bottom: 3%"></div>
        </el-row>
      </div>
      <el-backtop
        target=".container"
        :bottom="15"
        :right="15"
        :visibility-height="30"
      >
        <div
          style="
             {
              height: 100%;
              width: 100%;
              background-color: #ff8041;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
              text-align: center;
              line-height: 40px;
              color: #ffffff;
              border-radius: 50px;
              align-items: center;
              text-align: center;
            }
          "
        >
          <i class="el-icon-caret-top"></i>
        </div>
      </el-backtop>
    </div>

    <!-- <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-2"  ref="footnvabar">
      <div style="display:flex; flex-direction: row; margin-left: 5%;background-color: #10131f;">
        <ul>
            <li class="biaot" style="color:#f7f7f7">关于我们</li>
            <li class="biaot_span"></li>
            <li>
              <a href="#">公司介绍</a>
            </li>
            <li>
              <a href="#">公司历程</a>
            </li>
            <li>
              <a href="#">荣誉资质</a>
            </li>
            <li>
              <a href="#">企业文化</a>
            </li>
          </ul>

          <ul>
            <li class="biaot" style="color:#f7f7f7">
              <span>公司产品</span>
            </li>
            <li class="biaot_span"></li>
            <li>
              <a href="#">业务应用管理平台</a>
            </li>
            <li>
              <a href="#">测绘生产软件</a>
            </li>
            <li>
              <a href="#">财务管理平台</a>
            </li>
            <li>
              <a href="#">移动应用平台</a>
            </li>
          </ul>

          <ul>
            <li class="biaot" style="color:#f7f7f7">
              <span>项目案例</span>
            </li>
            <li class="biaot_span"></li>
            <li>
              <a href="#">合景泰富</a>
            </li>
            <li>
              <a href="#">科学城集团</a>
            </li>
            <li>
              <a href="#">时代中国</a>
            </li>
            <li>
              <a href="#">凯德中国</a>
            </li>
          </ul>

          <ul style=" width:350px;">
            <li class="biaot" style="color:#f7f7f7">
              <span>联系我们</span>
            </li>
            <li class="biaot_span"></li>
            <li style="color:#f7f7f7">
              <img src="../assets/foot_tb1.png" width="19" />020-89884232
            </li>
            <li style="color:#f7f7f7">
              <img src="../assets/foot_tb2.png" width="19" />www.cangshigroup.com
            </li>
            <li style="color:#f7f7f7">
              <img src="../assets/foot_tb3.png" width="19" />邮政编码：510000
            </li>
            <li style="color:#f7f7f7">
              <img src="../assets/foot_tb4.png" width="19" />广州市越秀区广卫路19-2金科大厦601
            </li>
          </ul>
      </div>
    </div> -->
  </div>
</template>
<script>
// import testcp from "@/components/testcomponents.vue";
import backTop from "@/components/backTop.vue";
// import GPTMenu from "@/components/gptmenu.vue";
// import { query } from "express";
export default {
  name: "homePage",
  components: { backTop },
  data() {
    return {
      screenWidth: 0,
      screenHeight: 0,
      nvabar1show: true,
      nvabar2show: false,
      pccentenshow: true,
      mobilecentenshow: false,
      mobileshow: true,
      hangdleclick: false,
      // isContainer: false,
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; // 实时宽度
        window.screenHeight = document.documentElement.clientHeight; // 实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
        that.hangdlenvabarshow();
        that.hangdlepsshow();
      })();
    };

    // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    window.screenWidth = document.documentElement.clientWidth; // 实时宽度
    window.screenHeight = document.documentElement.clientHeight; // 实时高度
    this.screenWidth = window.screenWidth;
    this.screenHeight = window.screenHeight;
    this.hangdlenvabarshow();
    this.hangdlepsshow();
    this.$nextTick(() => {
      this.toLocal();
    });
  },
  //用完后记得将存储的锚点置空，否则会影响其他页面跳转到当前页面
  destroyed() {
    localStorage.setItem("cec1", "");
  },
  methods: {
    hangdlenvabarshow() {
      if (this.screenWidth > 1080) {
        this.nvabar1show = true;
        // this.$refs.navbar1.style.display = 'none'
        // this.nvabar2show = true;
      } else {
        if (this.screenWidth < 1080) {
          this.nvabar1show = false;
        }
        // if (this.screenWidth >= 979) {
        //   this.nvabar1show = true;
        //   this.nvabar2show = false;
        // }
      }
    },
    hangdlepsshow() {
      if (this.screenWidth > 1080) {
        this.pccentenshow = true;
        this.mobilecentenshow = false;
      } else {
        this.pccentenshow = false;
        this.mobilecentenshow = true;
      }
    },
    FindMore() {
      this.$router.push("/CsIntroduce");
    },
    backhome() {
      this.$router.push("/");
    },
    Gocontact() {
      this.$router.push("/Contact");
    },
    jumpabout() {
      localStorage.setItem("cec", "aboutcs");
      this.$router.push({ path: "/CsIntroduce" });
    },
    jumpcourse() {
      localStorage.setItem("cec", "course");
      this.$router.push({ path: "/CsIntroduce" });
    },
    jumpculture() {
      localStorage.setItem("cec", "culture");
      this.$router.push({ path: "/CsIntroduce" });
    },
    jumpgov() {
      localStorage.setItem("cec2", "gov");
      this.$router.push({ path: "/Product" });
    },
    jumpent() {
      localStorage.setItem("cec2", "ent");
      this.$router.push({ path: "/Product" });
    },
    jumphejin() {
      localStorage.setItem("cec3", "hejin");
      this.$router.push({ path: "/Project" });
    },
    jumpbudongchan() {
      localStorage.setItem("cec3", "budongchan");
      this.$router.push({ path: "/Project" });
    },
    jumpshidai() {
      localStorage.setItem("cec3", "shidai");
      this.$router.push({ path: "/Project" });
    },
    jumpkaide() {
      localStorage.setItem("cec3", "kaide");
      this.$router.push({ path: "/Project" });
    },
    toLocal() {
      //查找存储的锚点id
      let Id = localStorage.getItem("cec1");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
      }
    },
    // toggleContainer() {
    //   if (this.screenWidth < 380) {
    //     this.isContainer = false;
    //   } else {
    //     this.isContainer = true;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: sticky;
  top: 0;
  // left: 0;
  // right: 0;
  z-index: 1;
  background-color: #fff;
  border: 0px;
  box-shadow: 0 0 13px 0 rgb(0 0 0 / 14%);
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 0;
  border-radius: 0;
}
.container {
  width: 100%;
  padding: 0;
  height: 100%;
  // overflow: auto;
  // overflow: hidden;
  overflow-x: hidden;
}

// .navbar-header {
//   align-items: center;
//   display: flex;
// }

.navbarbrand {
  padding: 15px 15px;
  float: left;
  font-size: 18px;
}

.navbar-nav {
  font-size: 18px;
  padding: 10px;
}

.nav-mod {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 1.5rem;
  background-color: #fff;
  overflow: hidden;
}

.yewu {
  width: 100%;
  float: left;
  margin-bottom: 2.8rem;
  margin-top: 1rem;
}

.btwz {
  background-color: #f7f7f7;
  margin: 10px;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.btwz img {
  margin: 3rem 0 1rem 0;
}

.btwz p.dz {
  width: 100%;
  font-size: 2.3rem;
}

.btwz p.xz {
  width: 100%;
  font-size: 1.5rem;
  color: #ccc;
  margin-bottom: 3rem;
  line-height: 1.5rem;
}

.anli_xm {
  width: 100%;
  float: left;
  margin-bottom: 2.8rem;
  margin-top: 1rem;
}

.tuwen p.wz {
  width: 100%;
  font-size: 2.2rem;
  margin-top: 1rem;
}

/*战略合作*/
.hezuo_xm {
  width: 100%;
  float: left;
  margin-bottom: 2.8rem;
  margin-top: 1rem;
}

.hezuo_xm ul {
  width: 100%;
  float: left;
}

.hezuo_xm ul li {
  width: 29.5%;
  margin-left: 3%;
  float: left;
  background: #f7f7f7;
  margin-top: 1.8rem;
  border-radius: 5px;
  text-align: center;
}

.hezuo_xm ul li img {
  margin: 0;
}

// 移动端核心业务
.ndz {
  margin: 10px 10px 0px 10px;
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  font-size: 14px;
  color: #10131f;
}

.nxz2 {
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  font-size: 12px;
  color: #ccc;
  // margin-bottom: 10px;
  line-height: 15px;
  text-align: center;
}

.nxz {
  margin: 10px;
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  min-height: 45px;
  font-size: 12px;
  color: #ccc;
  margin-bottom: 3rem;
  line-height: 1.5rem;
}

.bxz {
  margin: 10px;
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  min-height: 45px;
  font-size: 14px;
  color: #10131f;
  margin-top: 1rem;
  text-align: center;
  text-decoration: none;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.navright {
  padding: 20px 20px 20px 0;
  text-align: center;
  font-size: 20px;
  display: flex;
}

.navwz {
  color: #0381ca;
  text-decoration: none;
  font-weight: bolder;
}

.navwz:hover {
  color: #ff8041;
  text-decoration: none;
  font-weight: bolder;
}

// 导航栏下横线
.navwz {
  position: relative;
  box-sizing: border-box;
}

.navwz::after {
  content: "";
  width: 0;
  height: 3px;
  background: #ff8041;
  position: absolute;
  left: 50%;
  transition: all 0.4s;
  bottom: -10px;
}

.navwz:hover::after {
  left: 0;
  width: 100%;
  bottom: -10px;
}

.xl {
  position: relative;
  box-sizing: border-box;
}

.xl::after {
  content: "";
  width: 0;
  height: 3px;
  background: #ff8041;
  position: absolute;
  left: 50%;
  transition: all 0.4s;
}

.xl:hover::after {
  left: 0;
  width: 100%;
}

.navmenu li.xl {
  background: none;
}

// 首页介绍
.guanyu_left .btzx {
  height: 80px;
  border-left: #ff8041 5px solid;
  float: left;
  padding-left: 10px;
}

.bt {
  font-size: 35px;
  line-height: 45px;
  // font-weight: bold;
  padding-bottom: 0px;
}

.fbt {
  // float: left;
  line-height: 25px;
  // color: #999;
  text-transform: uppercase;
  padding-top: 5px;
  font-size: 20px;
}

.wenben {
  // float: left;
  line-height: 40px;
  color: #666;
  padding-top: 10px;
  font-size: 16px;
  overflow: hidden;
  // white-space: pre-wrap;
  // width: 620px;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 6;
  // -webkit-box-orient: vertical;
}

// 首页介绍按钮
.btn_dj {
  float: left;
  margin-top: 5%;
  width: 58px;
  height: 58px;
  background: url("../assets/anniu_jt.png") no-repeat;
  transition: all 0.4s;
}

.btn_dj:hover {
  float: left;
  width: 58px;
  height: 58px;
  background: url("../assets/anniu_jt_cs.png") no-repeat;
  display: block;
  transition: all 0.4s;
}

.nbtzx {
  height: 80px;
  border-left: #ff8041 5px solid;
  float: left;
  padding-left: 10px;
}

.nbt {
  font-size: 40px;
  line-height: 45px;
  font-weight: bold;
  padding-bottom: 0px;
}

.nbtp {
  float: left;
  line-height: 25px;
  color: #999;
  text-transform: uppercase;
  padding-top: 5px;
}

.yewe_gyk1 {
  // // width: 275px
  // height: 275px;
  // float: left;
  // border: 1px #eaeaea solid;
}

.yewe_gyk1 a {
  // width: 100%;
  //height: 63px;
  text-align: center;
  font-size: 25px;
  display: block;
  cursor: pointer;
  padding-top: 110px;
  background: url("../assets/zwu_chengs_1.png") no-repeat top center;
  // background-color: #fff;
  color: #10131f;
  transition: all 0.3s ease;
}

.yewe_gyk1 a:hover {
  // width: 100%;
  // // width: 275px
  // height: 63px;
  background: url("../assets/zwu_huise_1.png") no-repeat top center;
  // background-color: #fff;
  display: block;
  color: #ff8041;
  text-decoration: none;
}
.yewe_gyk2 {
  // // width: 275px
  // height: 275px;
  // float: left;
  // border: 1px #eaeaea solid;
}

.yewe_gyk2 a {
  // width: 100%;
  //height: 63px;
  text-align: center;
  font-size: 25px;
  display: block;
  cursor: pointer;
  padding-top: 110px;
  background: url("../assets/zwu_chengs_2.png") no-repeat top center;
  // background-color: #fff;
  transition: all 0.3s ease;
  color: #10131f;
}

.yewe_gyk2 a:hover {
  // width: 100%;
  // // width: 275px
  // height: 63px;
  background: url("../assets/zwu_huise_2.png") no-repeat top center;
  // background-color: #fff;
  display: block;
  color: #ff8041;
  text-decoration: none;
}

.yewe_gyk3 {
  // // width: 275px
  // height: 275px;
  // float: left;
  // border: 1px #eaeaea solid;
}

.yewe_gyk3 a {
  // width: 100%;
  //height: 63px;
  text-align: center;
  font-size: 25px;
  display: block;
  cursor: pointer;
  padding-top: 110px;
  background: url("../assets/zwu_chengs_3.png") no-repeat top center;
  // background-color: #fff;
  color: #10131f;
  transition: all 0.3s ease;
}

.yewe_gyk3 a:hover {
  // width: 100%;
  // // width: 275px
  // height: 63px;
  background: url("../assets/zwu_huise_3.png") no-repeat top center;
  // background-color: #fff;
  display: block;
  color: #ff8041;
  text-decoration: none;
}

.yewe_gyk4 {
  // // width: 275px
  // height: 275px;
  // float: left;
  // border: 1px #eaeaea solid;
}

.yewe_gyk4 a {
  // width: 100%;
  //height: 63px;
  text-align: center;
  font-size: 25px;
  display: block;
  cursor: pointer;
  padding-top: 110px;
  background: url("../assets/zwu_chengs_4.png") no-repeat top center;
  // background-color: #fff;
  color: #10131f;
  transition: all 0.3s ease;
}

.yewe_gyk4 a:hover {
  // width: 100%;
  // // width: 275px
  // height: 63px;
  background: url("../assets/zwu_huise_4.png") no-repeat top center;
  // background-color: #fff;
  display: block;
  color: #ff8041;
  text-decoration: none;
}

.yewe_gyk5 {
  // // width: 275px
  // height: 275px;
  // float: left;
  // border: 1px #eaeaea solid;
}

.yewe_gyk5 a {
  // width: 100%;
  //height: 63px;
  text-align: center;
  font-size: 25px;
  display: block;
  cursor: pointer;
  padding-top: 110px;
  background: url("../assets/zwu_chengs_5.png") no-repeat top center;
  // background-color: #fff;
  color: #10131f;
  transition: all 0.3s ease;
}

.yewe_gyk5 a:hover {
  // width: 100%;
  // // width: 275px
  // height: 63px;
  background: url("../assets/zwu_huise_5.png") no-repeat top center;
  // background-color: #fff;
  display: block;
  color: #ff8041;
  text-decoration: none;
}

.yewe_gyk6 {
  // // width: 275px
  // height: 275px;
  // float: left;
  // border: 1px #eaeaea solid;
}

.yewe_gyk6 a {
  // width: 100%;
  //height: 63px;
  text-align: center;
  font-size: 25px;
  display: block;
  cursor: pointer;
  padding-top: 110px;
  background: url("../assets/zwu_chengs_6.png") no-repeat top center;
  // background-color: #fff;
  color: #10131f;
  transition: all 0.3s ease;
}

.yewe_gyk6 a:hover {
  // width: 100%;
  // // width: 275px
  // height: 63px;
  background: url("../assets/zwu_huise_6.png") no-repeat top center;
  // background-color: #fff;
  display: block;
  color: #ff8041;
  text-decoration: none;
}

.yewe_gyk7 {
  // // width: 275px
  // height: 275px;
  // float: left;
  // border: 1px #eaeaea solid;
}

.yewe_gyk7 a {
  // width: 100%;
  //height: 63px;
  text-align: center;
  font-size: 25px;
  display: block;
  cursor: pointer;
  padding-top: 110px;
  background: url("../assets/zwu_chengs_7.png") no-repeat top center;
  // background-color: #fff;
  color: #10131f;
  transition: all 0.3s ease;
}

.yewe_gyk7 a:hover {
  // width: 100%;
  // // width: 275px
  // height: 63px;
  background: url("../assets/zwu_huise_7.png") no-repeat top center;
  // background-color: #fff;
  display: block;
  color: #ff8041;
  text-decoration: none;
}

.yewe_gyk8 {
  // // width: 275px
  // height: 275px;
  // float: left;
  // border: 1px #eaeaea solid;
}

.yewe_gyk8 a {
  // width: 100%;
  //height: 63px;
  text-align: center;
  font-size: 25px;
  display: block;
  cursor: pointer;
  padding-top: 110px;
  background: url("../assets/zwu_chengs_9.png") no-repeat top center;
  // background-color: #fff;
  color: #10131f;
  transition: all 0.3s ease;
}

.yewe_gyk8 a:hover {
  // width: 100%;
  // // width: 275px
  // height: 63px;
  background: url("../assets/zwu_huise_9.png") no-repeat top center;
  // background-color: #fff;
  display: block;
  color: #ff8041;
  text-decoration: none;
}

.yewe_gyk9 {
  // // width: 275px
  // height: 275px;
  // float: left;
  // border: 1px #eaeaea solid;
}

.yewe_gyk9 a {
  // width: 100%;
  //height: 63px;
  text-align: center;
  font-size: 25px;
  display: block;
  cursor: pointer;
  padding-top: 110px;
  background: url("../assets/zwu_chengs_8.png") no-repeat top center;
  // background-color: #fff;
  color: #10131f;
  transition: all 0.3s ease;
}

.yewe_gyk9 a:hover {
  // width: 100%;
  // // width: 275px
  // height: 63px;
  background: url("../assets/zwu_huise_8.png") no-repeat top center;
  // background-color: #fff;
  display: block;
  color: #ff8041;
  text-decoration: none;
}
// .yewe_bg {
//   // // width: 275px
//   height: 275px;
//   float: left;
//   // border: 1px #eaeaea solid;
// }

// .yewe_bg a {
//   // // width: 275px
//   height: 63px;
//   text-align: center;
//   font-size: 16px;
//   display: block;
//   cursor: pointer;
//   padding-top: 226px;
//   background: url("../assets/zwu_over2.png") no-repeat top center;
//   background-color: #fbfbfb;
//   color: #10131f;
// }

// .yewe_bg a:hover {
//   background: url("../assets/zwu_a2.png") no-repeat top center;
//   background-color: #fbfbfb;
//   display: block;
//   color: #ff8041;
//   text-decoration: none;
// }

// .right_mb {
//   //// width: 275px
//   height: 275px;
//   float: left;
//   // border-left: 1px #eaeaea solid;
//   // border-top: 1px #eaeaea solid;
//   // border-right: 1px #eaeaea solid;
// }

// .right_mb a {
//   // // width: 275px
//   height: 63px;
//   text-align: center;
//   font-size: 16px;
//   display: block;
//   cursor: pointer;
//   padding-top: 226px;
//   background: url("../assets/zwu_over3.png") no-repeat top center;
//   background-color: #fff;
//   color: #10131f;
// }

// .right_mb a:hover {
//   background: url("../assets/zwu_a3.png") no-repeat top center;
//   background-color: #fff;
//   display: block;
//   color: #ff8041;
//   text-decoration: none;
// }

// .yewe_gyk2 {
//   // width: 275px
//   height: 275px;
//   float: left;
// }

// .yewe_gyk2 a {
//   // width: 275px
//   height: 63px;
//   text-align: center;
//   font-size: 16px;
//   display: block;
//   cursor: pointer;
//   padding-top: 226px;
//   background: url("../assets/zwu_over4.png") no-repeat top center;
//   background-color: #fbfbfb;
//   color: #10131f;
// }

// .yewe_gyk2 a:hover {
//   background: url("../assets/zwu_a4.png") no-repeat top center;
//   background-color: #fbfbfb;
//   display: block;
//   color: #ff8041;
//   text-decoration: none;
// }

// .yewe_bg2 {
//   width: 200px;
//   height: 275px;
//   float: left;
// }

// .yewe_bg2 a {
//   // width: 275px
//   height: 63px;
//   text-align: center;
//   font-size: 16px;
//   display: block;
//   cursor: pointer;
//   padding-top: 226px;
//   background: url("../assets/zwu_over5.png") no-repeat top center;
//   background-color: #ffffff;
//   color: #10131f;
// }

// .yewe_bg2 a:hover {
//   background: url("../assets/zwu_a5.png") no-repeat top center;
//   background-color: #ffffff;
//   display: block;
//   color: #ff8041;
//   text-decoration: none;
// }

// .right_mb2 {
//   height: 275px;
//   float: left;
//   width: 200px;
// }

// .right_mb2 a {
//   // width: 275px
//   height: 63px;
//   text-align: center;
//   font-size: 16px;
//   display: block;
//   cursor: pointer;
//   padding-top: 226px;
//   background: url("../assets/zwu_over6.png") no-repeat top center;
//   background-color: #fbfbfb;
//   color: #10131f;
// }

// .right_mb2 a:hover {
//   background: url("../assets/zwu_a6.png") no-repeat top center;
//   background-color: #fbfbfb;
//   display: block;
//   color: #ff8041;
//   text-decoration: none;
// }

.xuq_bd input {
  line-height: 60px;
  float: left;
  padding: 5px 35px;
  color: #b4b3b3;
  border: 1px #eaeaea solid;
  border-radius: 10px;
  width: 100%;
  height: 58px;
  margin-right: 20px;
}

.xuq_nput {
  line-height: 60px;
  float: right;
  background: #ff8041;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 58px;
  cursor: pointer;
  border: none;
}

.foot {
  // width: 1920px;
  // height: 235px;
  background: #1b2033;
  margin-top: 0px;
  clear: both;
}

.about {
  // margin-top: 15%;
  clear: both;
  line-height: 35px;
  color: #ccc;
  font-size: 23px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}

.about::after {
  content: "";
  width: 0px;
  height: 3px;
  background: #ff8041;
  position: absolute;
  left: 0;
  transition: all 0.4s;
  bottom: -5px;
}

.about:hover::after {
  left: -1;
  width: 15%;
  bottom: -5px;
}

.aboutline {
  width: 35px;
  height: 5px;
  // border-bottom: 3px #ff8041 solid;
  margin-bottom: 10px;
}

.aboutitem {
  clear: both;
  line-height: 35px;
  color: #ccc;
  font-size: 15px;
  white-space: nowrap;
  margin-left: 2%;
  cursor: pointer;
  transition: color 0.4s ease;
  text-decoration: none;
}
.aboutitem:hover {
  color: #ff8041;
  // transform: translateX(-5px);
}

// 移动端底部
.banqz {
  width: 100%;
  text-align: center;
  color: #a6a7a9;
  font-size: 12px;
  background-color: #10131f;
}

// 移动端中间标题
.ntz {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 2.2rem;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
}

.nxd {
  width: 100%;
  text-align: center;
  color: #999;
  font-size: 1.4rem;
  width: 100%;
  // margin-top: 5px;
  text-transform: uppercase;
}

.bgcolor {
  background-color: #f7f7f7;
  padding-bottom: 10px;
}

.tbbgcolor {
  background-color: #fff;
}

.footnav {
  text-align: center;
}

.footnav a {
  font-size: 14px;
  color: #fff;
}

// PC端背景图
.aboutbg {
  background: url("../assets/bj_about.jpg") no-repeat center;
  background-size: cover;
}

.yewubg {
  background: url("../assets/bolan.jpg") repeat center;
}

.anlibg {
  background: url("../assets/anli.jpg") no-repeat center;
  background-size: cover;
}

.hezbg {
  background: url("../assets/hez_bg.jpg") no-repeat center;
  background-size: cover;
}

.aboutwz {
  text-indent: 2em;
  line-height: 30px;
  font-size: 1.6rem;
  margin-top: 1rem;
}

.navtb {
  font-size: 1.8rem;
  padding: 10px;
  font-weight: bolder;
}

// .navtb a {
//   color: #333 !important;
// }

// .navtb a:hover {
//   text-decoration: none;
//   color: #333;
// }
.cooperation-title {
  // text-align: center;
  // font-weight: bolder;
  font-size: 30px;
}
.zizhi-title {
  font-size: 20px;
  line-height: 30px;
  height: 30px;
  margin-top: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-weight: bolder;
}
.zizhi-content {
  margin-top: 20px;
  font-size: 16px;
  line-height: 30px;
  height: 120px;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.yewumbbt {
  font-size: 1.8rem;
  margin-top: 3%;
}
.zizhibt {
  font-weight: bolder;
  font-size: 1.8rem;
  line-height: 30px;
  margin-top: 3%;
}
.zizhiwz {
  font-size: 1.4rem;
  line-height: 25px;
  margin-top: 3%;
}
//移动端侧边菜单栏样式调整
::v-deep .el-menu .el-menu-item {
  margin: 10px;
}
::v-deep.el-menu .el-menu-item:hover {
  outline: 0 !important;
  color: #ff8041 !important;
  background: #eeeeee;
  margin: 10px;
  border-radius: 10px;
  // background: linear-gradient(270deg, #f2f7fc 0%, #fefefe 100%) !important;
}
::v-deep.el-menu .el-menu-item.is-active {
  color: #ff8041 !important;
  background: #eeeeee;
  margin: 10px;
  border-radius: 10px;
  // background: linear-gradient(270deg, #f2f7fc 0%, #fefefe 100%) !important;
}
.footwz_mb {
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
}
</style>
