<template>
  <div class="header">
    <div class="header_tab" ref="headertab">
      <ul ref="tabitem" style="padding: 0px">
        <li
          v-for="(item, index) in listArray"
          :key="index"
          :class="index == current ? 'activeheader' : ''"
          @click="getTab(index, $event)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderTab",
  props: {
    listArray: {
      type: Array,
    },
    current: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  methods: {
    getTab(index, e) {
      this.$emit("change", index, e);
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 45px;
  // background-color: #888888;
  display: flex;
  text-align: center;
}
.header_tab {
  width: 100%;
  height: 45px;
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
}
.header_tab::-webkit-scrollbar {
  display: none;
}
ul {
  display: inline-block;
  white-space: nowrap;
}
li {
  display: inline-block;
  line-height: 45px;
  padding: 0px 10px;
  font-size: 14px;
  color: #333;
  // 点击高亮某一项时,将原来的字体变大,会导致没有高亮的元素距离上面有空隙,会出现纵向滚动条
  margin-top: -1px;
}
.activeheader {
  font-size: 16px;
  // font-weight: bolder;
  position: relative;
  color: #ff8041;
}
.activeheader:after {
  position: absolute;
  content: "";
  width: 85%;
  height: 2px;
  bottom: 0;
  left: 10px;
  background-color: #ff8041;
  border-radius: 50px;
}
</style>
