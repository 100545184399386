<template>
  <Contact />
</template>

<script>
// @ is an alias to /src
import Contact from '@/components/Contact.vue';
export default {
  name: 'CsContact',
  components: {
    Contact
  }
}
</script>