<template>
  <div class="container">
    <div class="pcview" v-if="pccentenshow">
      <!-- 导航栏 -->
      <el-row class="navbar navbar-default">
        <el-col :span="12">
          <el-row style="display: flex; align-items: center; margin-left: 15px">
            <img src="../assets/cslogo.png" width="430px" height="50px" />
          </el-row>
        </el-col>
        <el-col :span="12">
          <div
            class="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
            ref="navbar1"
            v-if="nvabar1show"
          >
            <el-row class="navright">
              <el-col :span="4" class="nav_item">
                <router-link to="/" class="navwz">首页</router-link>
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/CsIntroduce" class="navwz"
                  >公司介绍</router-link
                >
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/Product" class="navwz">产品领域</router-link>
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/Project" class="navwz">项目案例</router-link>
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/Contact" class="navwz">联系我们</router-link>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <!-- 菜单栏 -->
      <el-row>
        <img src="../assets/about_bg.jpg" width="100%" height="100%" />
      </el-row>
      <!-- <el-row class="menubg">
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple-light"></div>
        </el-col>
        <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
          <div>
            <el-row>
              <div style="height: 186px"></div>
            </el-row>
            <el-row
              style="
                background-color: #ffffff;
                align-items: center;
                display: flex;
              "
            >
              <el-col :span="1">
                <img src="../assets/shubiao.gif" />
              </el-col>
              <el-col :span="4" style="text-align: center">
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <img src="../assets/ali_xtb.jpg" />
                </el-row>
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <div class="menuwz"><a href="#">项目案例</a></div>
                </el-row>
              </el-col>
              <el-col :span="18">
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <el-col :span="23">
                    <div class="menuwz">
                      <router-link to="/" style="float: right"
                        >返回首页</router-link
                      >
                    </div>
                  </el-col>
                  <el-col :span="1">
                    <img src="../assets/return.jpg" />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple"></div>
        </el-col>
      </el-row> -->

      <!-- 标题 -->
      <el-row style="background: #f9f9f9">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row>
              <el-col :span="24">
                <div style="text-align: center; margin-top: 30px">
                  <div class="bt">项目案例</div>
                  <div class="fbt">Project Case</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 3%"> </el-row>
      </el-row>
      <!-- 内容 -->
      <el-row style="background: #f9f9f9">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="20">
            <ProjectTabs></ProjectTabs>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-top: 40px"></div>
        </el-row>
      </el-row>
      <div class="foot">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="20">
            <el-row style="margin-top: 30px"></el-row>
            <el-row style="display: flex; align-items: center">
              <el-col :xs="4" :sm="4" :md="3" :lg="3" :xl="3">
                <img src="../assets/logo-foot.png" width="100%" />
              </el-col>
              <el-col :xs="20" :sm="20" :md="21" :lg="21" :xl="21">
                <el-row style="margin-left: 150px">
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">公司介绍</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpabout">关于仓实</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpcourse">
                          公司历程
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpyewu">核心业务</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpzizhi">公司资质</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpculture">
                          企业文化
                        </div>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">公司产品</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpgov">
                          政府信息化应用平台
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpgov">
                          政府行业技术服务
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpent">
                          企业信息化应用平台
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpent">
                          企业行业技术服务
                        </div>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">项目案例</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem">合景泰富</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem">不动产信息建设</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem">时代中国</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem">凯德中国</div>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">联系我们</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb1.png"
                          width="20px"
                          height="18px"
                        />
                        <div class="aboutitem">020-89884234</div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb2.png"
                          width="20px"
                          height="20px"
                        />
                        <div class="aboutitem">
                          zhongweiming@cangshigroup.com
                        </div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb3.png"
                          width="20px"
                          height="16px"
                        />
                        <div class="aboutitem">邮政编码:510000</div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb4.png"
                          width="20px"
                          height="21px"
                        />
                        <div class="aboutitem">
                          广州市越秀区广卫路19-2金科大厦6楼
                        </div>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-top: 20px"></div>
        </el-row>
        <el-row>
          <div
            style="
              text-align: center;
              color: #ccc;
              line-height: 30px;
              font-size: 14px;
              font-weight: lighter;
              border-top: 1px solid #222634;
            "
          >
            CopyRight © 2022 All Right Reserved 广州仓实信息科技有限公司
            粤ICP备07032789号
          </div>
        </el-row>
      </div>
      <back-top />
      <el-backtop
        target=".container"
        :bottom="30"
        :right="50"
        :visibility-height="400"
      >
        <div
          style="
             {
              height: 100%;
              width: 100%;
              background-color: #ff8041;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
              text-align: center;
              line-height: 40px;
              color: #ffffff;
              border-radius: 50px;
              align-items: center;
              text-align: center;
            }
          "
        >
          <i class="el-icon-caret-top"></i>
        </div>
      </el-backtop>
    </div>
    <!-- 移动端 -->
    <div class="mobileshow" v-if="mobilecentenshow">
      <el-row
        style="display: flex; align-items: center; padding: 10px 10px 10px 10px"
      >
        <el-col :span="20">
          <div @click="backhome">
            <img src="../assets/cslogo-mb.png" style="width: 100%" />
          </div>
        </el-col>
        <el-col :span="4" style="text-align: end">
          <div @click="hangdleclick = true">
            <img src="../assets/menu_b.png" style="width: 50%" />
          </div>
        </el-col>
      </el-row>

      <el-drawer
        title=""
        :visible.sync="hangdleclick"
        :direction="direction"
        size="60%"
        :overflow-y="hidden"
      >
        <el-row>
          <el-menu default-active="/Project" router active-text-color="#ff8041">
            <el-menu-item index="/">
              <template slot="title">
                <div @click="hangdleclick = false" style="padding: 0 0 0 10px">
                  <i class="el-icon-house" style="font-weight: bolder"></i>
                  <span class="navtb">首页</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/CsIntroduce">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i
                    class="el-icon-office-building"
                    style="font-weight: bolder"
                  ></i>
                  <span class="navtb">公司介绍</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/Product">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i class="el-icon-box" style="font-weight: bolder"></i>
                  <span class="navtb">产品领域</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/Project">
              <template slot="title">
                <div
                  style="
                    padding: 0 0 0 10px;
                    background-color: #eeeeee;
                    border-radius: 10px;
                  "
                >
                  <i
                    class="el-icon-data-analysis"
                    style="font-weight: bolder"
                  ></i>
                  <span class="navtb">项目案例</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/Contact">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i class="el-icon-message" style="font-weight: bolder"></i>
                  <span class="navtb">联系我们</span>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-row>
      </el-drawer>
      <el-row>
        <img src="../assets/mb_about_bg.jpg" width="100%" />
      </el-row>
      <!-- 移动端项目案例 -->
      <el-row>
        <el-row>
          <el-col :span="24">
            <div class="tltle">
              <p class="ntz">项目案例</p>
              <p class="nxd">project case</p>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
            <!-- tabs样式1 -->
            <testTabs
              :listArray="list"
              :current="current"
              @change="changeTab"
              ref="tab"
            >
            </testTabs>
            <div v-if="current == '0'" style="margin-top: 20px">
              <el-card
                v-for="(item, index) in softwareprojectList"
                :key="index"
                style="margin-bottom: 20px"
              >
                <el-row :gutter="20" style="display: flex; align-items: center">
                  <el-col :span="12">
                    <el-image style="width: 100%" :src="item.img"></el-image>
                  </el-col>
                  <el-col :span="12">
                    <div>
                      <div
                        style="font-size: 1.8rem; font-weight: bolder"
                        :id="item.id"
                      >
                        {{ item.projectname }}
                      </div>
                      <div style="font-size: 1.6rem; margin-top: 10px">
                        {{ item.projectyear }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </div>
            <div v-if="current == '1'" style="margin-top: 20px">
              <el-card
                v-for="(item, index) in informationizeprojectList"
                :key="index"
                style="margin-bottom: 20px"
              >
                <el-row :gutter="20" style="display: flex; align-items: center">
                  <el-col :span="12">
                    <el-image style="width: 100%" :src="item.img"></el-image>
                  </el-col>
                  <el-col :span="12">
                    <div>
                      <div
                        style="font-size: 1.8rem; font-weight: bolder"
                        :id="item.id"
                      >
                        {{ item.projectname }}
                      </div>
                      <div style="font-size: 1.6rem; margin-top: 10px">
                        {{ item.projectyear }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </div>
            <div v-if="current == '2'" style="margin-top: 20px">
              <el-card
                v-for="(item, index) in dataprojectList"
                :key="index"
                style="margin-bottom: 20px"
              >
                <el-row :gutter="20" style="display: flex; align-items: center">
                  <el-col :span="12">
                    <el-image style="width: 100%" :src="item.img"></el-image>
                  </el-col>
                  <el-col :span="12">
                    <div>
                      <div
                        style="font-size: 1.8rem; font-weight: bolder"
                        :id="item.id"
                      >
                        {{ item.projectname }}
                      </div>
                      <div style="font-size: 1.6rem; margin-top: 10px">
                        {{ item.projectyear }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </div>
            <!-- tabs样式2 -->
            <!-- <ProjectmbTabs></ProjectmbTabs> -->
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row>
      </el-row>
      <!-- 移动端底部 -->
      <div style="background-color: #1b2033">
        <el-row>
          <el-col :xs="2" :sm="2" :md="4" :lg="4" :xl="5">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="20" :sm="20" :md="16" :lg="16" :xl="14">
            <el-row style="margin-top: 30px">
              <img src="../assets/logo-footmb.png" width="50%" />
            </el-row>
            <el-row style="margin-top: 30px">
              <el-col :span="10">
                <router-link to="/CsIntroduce" class="footwz_mb"
                  >关于仓实</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
              <el-col :span="14">
                <router-link to="/Product" class="footwz_mb"
                  >公司产品</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 20px">
              <el-col :span="10">
                <router-link to="/Project" class="footwz_mb"
                  >项目案例</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
              <el-col :span="14">
                <router-link to="/Contact" class="footwz_mb"
                  >联系我们</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row
              style="color: #a6a7a9; font-size: 12px; font-weight: lighter"
            >
              <el-row> CopyRight © 2022 All Right Reserved </el-row>
              <el-row> 广州仓实信息科技有限公司 </el-row>
              <el-row> 粤ICP备07032789号 </el-row>
            </el-row>
            <!-- <el-row type="flex" justify="center" class="footnav">
              <el-col :span="6" style="border-right: 1px solid #a6a7a9">
                <router-link to="/">首页</router-link>
              </el-col>
              <el-col :span="6" style="border-right: 1px solid #a6a7a9">
                <router-link to="/CsIntroduce">公司介绍</router-link>
              </el-col>
              <el-col :span="6" style="border-right: 1px solid #a6a7a9">
                <router-link to="/Project">项目案例</router-link>
              </el-col>
              <el-col :span="6">
                <router-link to="/Contact">联系我们</router-link>
              </el-col>
            </el-row> -->
            <el-row>
              <div style="margin-top: 3%"></div>
            </el-row>
          </el-col>
          <el-col :xs="2" :sm="2" :md="4" :lg="4" :xl="5">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>

        <!-- <el-row justify="center" type="flex">
          <el-col :span="5">
            <div style="width: 20px"></div>
          </el-col>
          <el-col :span="14">
            <div class="banqz">
              CopyRight 2022 All Right Reserved <br />广州仓实信息科技有限公司
              粤ICP备07032789号
            </div>
          </el-col>
          <el-col :span="5">
            <div style="width: 20px"></div>
          </el-col>
        </el-row> -->
        <el-row>
          <div style="margin-bottom: 3%"></div>
        </el-row>
      </div>
      <el-backtop
        target=".container"
        :bottom="15"
        :right="15"
        :visibility-height="30"
      >
        <div
          style="
             {
              height: 100%;
              width: 100%;
              background-color: #ff8041;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
              text-align: center;
              line-height: 40px;
              color: #ffffff;
              border-radius: 50px;
              align-items: center;
              text-align: center;
            }
          "
        >
          <i class="el-icon-caret-top"></i>
        </div>
      </el-backtop>
    </div>
  </div>
</template>

<script>
import backTop from "@/components/backTop.vue";
import ProjectTabs from "@/components/project-tabs.vue";
// import ProjectmbTabs from "@/components/projectmb-tabs.vue";
import testTabs from "@/components/test_tabs.vue";
// import testcp from "@/components/testcomponents.vue";
export default {
  components: { backTop, ProjectTabs, testTabs },
  data() {
    return {
      currentPage: 1,
      screenWidth: 0,
      screenHeight: 0,
      nvabar1show: true,
      nvabar2show: false,
      pccentenshow: true,
      mobilecentenshow: false,
      mobileshow: true,
      hangdleclick: false,
      input: "",
      list: [],
      current: 0,
      softwareprojectList: [
        {
          projectname:
            "中国工商银行佛山分行与南海区大沥镇城建和水利办公室共同开发“征拆信息数据化管理平台”服务项目",
          projectyear: "2022年",
          img: require("../assets/anli_16.png"),
        },
        {
          projectname: "合生征拆管理系统建设项目",
          projectyear: "2022年",
          img: require("../assets/anli_15.png"),
        },
        {
          projectname:
            "广州市建设工程拆迁有限公司城市更新项目信息管理平台建设项目",
          projectyear: "2022年",
          img: require("../assets/anli_17.png"),
        },
        {
          projectname: "广州市不动产登记平台建设项目",
          projectyear: "2021年",
          img: require("../assets/anli_5.jpg"),
          id: "budongchan",
        },
        {
          projectname: "时代中国项目主数据系统开发项目",
          projectyear: "2021年",
          img: require("../assets/anli_4.jpg"),
        },
        {
          projectname: "星河湾城市更新系统建设及实施项目",
          projectyear: "2021年",
          img: require("../assets/anli_14.png"),
        },
        {
          projectname: "盛高城市更新系统销售及服务项目",
          projectyear: "2021年",
          img: require("../assets/anli_12.png"),
        },
        {
          projectname: "富力集团城更云平台项目",
          projectyear: "2021年",
          img: require("../assets/anli_11.png"),
        },
        {
          projectname:
            "广州机械科学研究院及其周边低效用地全面改造升级城市更新业务信息管理系统",
          projectyear: "2021年",
          img: require("../assets/anli_10.png"),
        },
        {
          projectname: "知识城拆迁系统项目",
          projectyear: "2020年",
          img: require("../assets/anli_3.jpg"),
        },
        {
          projectname: "智能人机交互建设技术服务项目",
          projectyear: "2020年",
          img: require("../assets/anli_9.png"),
        },
        {
          projectname: "时代中国城市更新拆迁信息管理系统使用技术服务项目",
          projectyear: "2020年",
          img: require("../assets/anli_4.jpg"),
        },
        {
          projectname: "合景泰富城市更新系统建设及实施项目",
          projectyear: "2020年",
          img: require("../assets/anli_1.jpg"),
          id: "hejin",
        },
        {
          projectname: "广州市土地林海登记系统开发项目",
          projectyear: "2019年",
          img: require("../assets/anli_8.png"),
        },
        {
          projectname: "时代中国城市更新拆迁信息管理系统开发项目",
          projectyear: "2019年",
          img: require("../assets/anli_4.jpg"),
          id: "shidai",
        },
        {
          projectname: "凯德中国房屋征收辅助决策分析系统开发项目",
          projectyear: "2018年",
          img: require("../assets/anli_6.jpg"),
          id: "kaide",
        },
        {
          projectname: "广东保顺检测鉴定有限公司房屋安全检测软件开发项目",
          projectyear: "2018年",
          img: require("../assets/anli7.png"),
          id: "",
        },
      ],
      informationizeprojectList: [
        {
          projectname: "黄埔区萝峰旧村改造测算咨询服务项目",
          projectyear: "2022年",
          img: require("../assets/anli_21.png"),
        },
        {
          projectname: "黄埔区萝峰村改造项目产权注销咨询技术服务合同",
          projectyear: "2021年",
          img: require("../assets/anli_21.png"),
        },
        {
          projectname: "凯德坦尾基础数据处理统筹咨询技术服务",
          projectyear: "2020年",
          img: require("../assets/anli_6.jpg"),
        },
        {
          projectname: "房屋安全技术培咨询项目",
          projectyear: "2020年",
          img: require("../assets/anli_20.png"),
        },
        {
          projectname: "不动产登记业务梳理咨询服务项目 ",
          projectyear: "2019年",
          img: require("../assets/anli_19.png"),
        },
        {
          projectname: "不动产土地林海登记业务研究咨询服务项目",
          projectyear: "2018年",
          img: require("../assets/anli_19.png"),
        },
        {
          projectname:
            "不动产统一登记机制保障业务—广州市白云区农村不动产权籍调查示范点咨询服务项目",
          projectyear: "2018年",
          img: require("../assets/anli_19.png"),
        },
        {
          projectname:
            "广州市城建安置房管理系统空间信息可视化应用咨询技术服务合同",
          projectyear: "2018年",
          img: require("../assets/anli_18.png"),
        },
      ],
      dataprojectList: [
        {
          projectname: "番禺区不动产登记空间数据整合成果融合入库咨询服务项目",
          projectyear: "2022年",
          img: require("../assets/anli_25.png"),
        },
        {
          projectname: "增城区不动产登记空间数据整合成果融合入库技术服务项目",
          projectyear: "2022年",
          img: require("../assets/anli_24.png"),
        },
        {
          projectname:
            "番禺区不动产空间数据户重落幢成果融合更新入库技术服务项目",
          projectyear: "2021年",
          img: require("../assets/anli_25.png"),
        },
        {
          projectname: "广州市中心六区不动产数据整理项目",
          projectyear: "2020年",
          img: require("../assets/anli_26.png"),
        },
        {
          projectname: "南沙区不动产登记空间数据整合成果融合入库技术服务项目",
          projectyear: "2019年",
          img: require("../assets/anli_23.png"),
        },
        {
          projectname:
            "佛山市南海区兴贤风梅岭村级工业园“工改工”城市更新改造过程技术服务项目",
          projectyear: "2019年",
          img: require("../assets/anli_22.png"),
        },
      ],
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; // 实时宽度
        window.screenHeight = document.documentElement.clientHeight; // 实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
        that.hangdlenvabarshow();
        that.hangdlepsshow();
      })();
    };
    // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    window.screenWidth = document.documentElement.clientWidth; // 实时宽度
    window.screenHeight = document.documentElement.clientHeight; // 实时高度
    this.screenWidth = window.screenWidth;
    this.screenHeight = window.screenHeight;
    this.hangdlenvabarshow();
    this.hangdlepsshow();
    this.$nextTick(() => {
      this.toLocal();
    });
    this.getlist();
  },
  //用完后记得将存储的锚点置空，否则会影响其他页面跳转到当前页面
  destroyed() {
    localStorage.setItem("cec3", "");
  },
  methods: {
    hangdlenvabarshow() {
      if (this.screenWidth > 1080) {
        this.nvabar1show = true;
        // this.$refs.navbar1.style.display = 'none'
        // this.nvabar2show = true;
      } else {
        if (this.screenWidth < 1080) {
          this.nvabar1show = false;
        }
        // if (this.screenWidth >= 979) {
        //   this.nvabar1show = true;
        //   this.nvabar2show = false;
        // }
      }
    },
    hangdlepsshow() {
      if (this.screenWidth > 1080) {
        this.pccentenshow = true;
        this.mobilecentenshow = false;
      } else {
        this.pccentenshow = false;
        this.mobilecentenshow = true;
      }
    },
    backhome() {
      this.$router.push("/");
    },
    jumpabout() {
      localStorage.setItem("cec", "aboutcs");
      this.$router.push({ path: "/CsIntroduce" });
    },
    jumpcourse() {
      localStorage.setItem("cec", "course");
      this.$router.push({ path: "/CsIntroduce" });
    },
    jumpculture() {
      localStorage.setItem("cec", "culture");
      this.$router.push({ path: "/CsIntroduce" });
    },
    jumpyewu() {
      localStorage.setItem("cec1", "yewu");
      this.$router.push({ path: "/" });
    },
    jumpzizhi() {
      localStorage.setItem("cec1", "zizhi");
      this.$router.push({ path: "/" });
    },
    jumpgov() {
      localStorage.setItem("cec2", "gov");
      this.$router.push({ path: "/Product" });
    },
    jumpent() {
      localStorage.setItem("cec2", "ent");
      this.$router.push({ path: "/Product" });
    },
    toLocal() {
      //查找存储的锚点id
      let Id = localStorage.getItem("cec3");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
      }
    },
    getlist() {
      this.list = [
        { current: 1, name: "软件开发建设项目" },
        { current: 2, name: "信息化咨询设计项目" },
        { current: 3, name: "数据分析整理项目" },
      ];
    },
    changeTab(index, e) {
      this.current = index; // 高亮当前
      let tab = this.$refs.tab.$refs.headertab; // 包裹 ul的 div
      let tabitem = this.$refs.tab.$refs.tabitem; // 包裹 li的 ul
      let winWidth = window.innerWidth; // 当前屏幕的宽度
      let liList = e.target; // 当前点击的li
      if (liList) {
        //  当前li左偏移, li的宽度, 中间值(当前屏幕的宽度 - li的宽度) /2, 目标值 (中间值 - 当前li左偏移), 整个ul的宽度
        let liLeft = liList.offsetLeft,
          liWidth = liList.offsetWidth,
          liCenter = (winWidth - liWidth) / 2,
          liTarget = liLeft - liCenter;
        let ulWidth = tabitem.offsetWidth;
        if (liTarget < 0) {
          tab.scrollLeft = 0;
          return;
        }
        // winWidth(375) - ulWidth(436) =  -61
        if (liTarget < winWidth - ulWidth) {
          tab.scrollLeft = -(winWidth - ulWidth) + liWidth;
          return;
        }
        tab.scrollLeft = liTarget;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding: 0;
  height: 100%;
  // overflow-y: auto;
  overflow-x: hidden;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  border: 0px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 0 13px 0 rgb(0 0 0 / 14%);
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 0;
}
.navbar-header {
  align-items: center;
  display: flex;
}
.navbar-nav {
  font-size: 18px;
  padding: 10px;
}
// 头部导航栏
.nbtzx {
  height: 41px;
  border-left: #888888 1px solid;
  float: left;
  padding-left: 10px;
}

.navwz {
  position: relative;
  box-sizing: border-box;
}

.navwz::after {
  content: "";
  width: 0;
  height: 3px;
  background: #ff8041;
  position: absolute;
  left: 50%;
  transition: all 0.4s;
  bottom: -10px;
}

.navwz:hover::after {
  left: 0;
  width: 100%;
  bottom: -10px;
}

.xl {
  position: relative;
  box-sizing: border-box;
}

.xl::after {
  content: "";
  width: 0;
  height: 3px;
  background: #ff8041;
  position: absolute;
  left: 50%;
  transition: all 0.4s;
}

.xl:hover::after {
  left: 0;
  width: 100%;
}

.navwz {
  color: #0381ca;
  text-decoration: none;
  font-weight: bolder;
}

.navwz:hover {
  color: #ff8041;
  text-decoration: none;
  font-weight: bolder;
}

.navleft {
  padding: 20px 0 20px 20px;
  align-items: center;
  display: flex;
}

.navright {
  padding: 20px 20px 20px 0;
  text-align: center;
  font-size: 20px;
  display: flex;
}

// 菜单栏
.menubg {
  background: url("../assets/about_bg.jpg") no-repeat center;
}

.menuwz a {
  font-size: 18px;
  color: black;
  text-decoration: none;
}

.menuwz a:hover {
  color: #ff8041;
  text-decoration: none;
}

.nbtzx1 {
  height: 80px;
  border-left: #ff8041 5px solid;
  float: left;
  padding-left: 10px;
}

.nbt {
  font-size: 40px;
  line-height: 45px;
  font-weight: bold;
  padding-bottom: 0px;
}

.nfbt {
  float: left;
  line-height: 25px;
  color: #999;
  text-transform: uppercase;
  padding-top: 5px;
  font-size: 16px;
}

.anliwz {
  line-height: 20px;
  background: #fff;
  padding: 10px 0 0 10px;
  font-size: 16px;
}

.foot {
  // width: 1920px;
  // height: 235px;
  background: #1b2033;
  margin-top: 0px;
  clear: both;
}

.about {
  // margin-top: 15%;
  clear: both;
  line-height: 35px;
  color: #ccc;
  font-size: 23px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}

.about::after {
  content: "";
  width: 0px;
  height: 3px;
  background: #ff8041;
  position: absolute;
  left: 0;
  transition: all 0.4s;
  bottom: -5px;
}

.about:hover::after {
  left: -1;
  width: 15%;
  bottom: -5px;
}

.aboutline {
  width: 35px;
  height: 5px;
  // border-bottom: 3px #ff8041 solid;
  margin-bottom: 10px;
}

.aboutitem {
  clear: both;
  line-height: 35px;
  color: #ccc;
  font-size: 15px;
  white-space: nowrap;
  margin-left: 2%;
  cursor: pointer;
  transition: color 0.4s ease;
  text-decoration: none;
}
.aboutitem:hover {
  color: #ff8041;
  // transform: translateX(-5px);
}

.ntz {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 2.2rem;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
}

.nxd {
  width: 100%;
  text-align: center;
  color: #999;
  font-size: 1.4rem;
  width: 100%;
  // margin-top: 5px;
  text-transform: uppercase;
}

.projectbt {
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-weight: bolder;
}
.projectwz {
  font-size: 1.4rem;
  line-height: 2rem;
}
.footnav {
  text-align: center;
}

.footnav a {
  font-size: 14px;
  color: #fff;
}

.banqz {
  width: 100%;
  text-align: center;
  color: #a6a7a9;
  font-size: 12px;
  background-color: #10131f;
}
.bt {
  font-size: 35px;
  line-height: 45px;
  // font-weight: bold;
  padding-bottom: 0px;
}

.fbt {
  // float: left;
  line-height: 25px;
  // color: #999;
  text-transform: uppercase;
  padding-top: 5px;
  font-size: 20px;
}
.xm-tilte {
  font-size: 25px;
  line-height: 30px;
  font-weight: bolder;
}
.xm-year {
  margin-top: 15px;
  line-height: 30px;
  font-size: 16px;
}
.xm-tilte .xmbt {
  text-decoration: none;
  cursor: pointer;
  color: black;
}
.xm-tilte .xmbt:hover {
  color: #ff8041;
  text-decoration: none;
}
.navtb {
  font-size: 1.8rem;
  padding: 10px;
  font-weight: bolder;
}
//移动端侧边菜单栏样式调整
::v-deep .el-menu .el-menu-item {
  margin: 10px;
}
::v-deep.el-menu .el-menu-item:hover {
  outline: 0 !important;
  color: #ff8041 !important;
  background: #eeeeee;
  margin: 10px;
  border-radius: 10px;
  // background: linear-gradient(270deg, #f2f7fc 0%, #fefefe 100%) !important;
}
::v-deep.el-menu .el-menu-item.is-active {
  color: #ff8041 !important;
  background: #eeeeee;
  margin: 10px;
  border-radius: 10px;
  // background: linear-gradient(270deg, #f2f7fc 0%, #fefefe 100%) !important;
}
.footwz_mb {
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
}
</style>
