<template>
  <div class="container">
    <div class="pcview" v-if="pccentenshow">
      <!-- 导航栏 -->
      <el-row class="navbar navbar-default">
        <el-col :span="12">
          <el-row style="display: flex; align-items: center; margin-left: 15px">
            <img src="../assets/cslogo.png" width="430px" height="50px" />
          </el-row>
        </el-col>
        <el-col :span="12">
          <div
            class="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
            ref="navbar1"
            v-if="nvabar1show"
          >
            <el-row class="navright">
              <el-col :span="4" class="nav_item">
                <router-link to="/" class="navwz">首页</router-link>
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/CsIntroduce" class="navwz"
                  >公司介绍</router-link
                >
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/Product" class="navwz">产品领域</router-link>
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/Project" class="navwz">项目案例</router-link>
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/Contact" class="navwz">联系我们</router-link>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <!-- <el-row>
        <div
          class="collapse navbar-collapse"
          id="bs-example-navbar-collapse-2"
          ref="navbar2"
          v-if="nvabar2show"
        >
          <ul class="nav navbar-nav">
            <li>
              <router-link to="/" class="navwz">首页</router-link>
            </li>
            <li>
              <router-link to="/CsIntroduce" class="navwz"
                >公司介绍</router-link
              >
            </li>
            <li>
              <router-link to="/Product" class="navwz">产品领域</router-link>
            </li>
            <li>
              <router-link to="/Project" class="navwz">项目案例</router-link>
            </li>
            <li>
              <router-link to="/Contact" class="navwz">联系我们</router-link>
            </li>
          </ul>
        </div>
      </el-row> -->
      <!-- 菜单 -->
      <el-row>
        <img src="../assets/about_bg.jpg" width="100%" height="100%" />
      </el-row>
      <!-- <el-row>
        <div>
          <el-row>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple-light"></div>
            </el-col>
            <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
              <el-row style="text-align: center; margin-top: 20px">
                <el-col :span="6">
                  <el-row>
                    <img src="../assets/about_tb1.jpg" />
                    <div class="menuwz"><a href="#1">公司简介</a></div>
                  </el-row>
                </el-col>
                <el-col :span="6">
                  <el-row>
                    <img src="../assets/about_tb2.jpg" />
                    <div class="menuwz"><a href="#2">发展历程</a></div>
                  </el-row>
                </el-col>
                <el-col :span="6">
                  <el-row>
                    <img src="../assets/about_tb3.jpg" />
                    <div class="menuwz"><a href="#3">荣誉资质</a></div>
                  </el-row>
                </el-col>
                <el-col :span="6">
                  <el-row>
                    <img src="../assets/about_tb4.jpg" />
                    <div class="menuwz"><a href="#4">企业文化</a></div>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple-light"></div>
            </el-col>
          </el-row>
        </div>
      </el-row> -->

      <!-- <el-row class="menubg">
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple-light"></div>
        </el-col>
        <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
          <div>
            <el-row>
              <div style="height: 186px"></div>
            </el-row>
            <el-row
              style="
                background-color: #ffffff;
                align-items: center;
                display: flex;
              "
            >
              <el-col :span="1">
                <img src="../assets/shubiao.gif" />
              </el-col>
              <el-col :span="4" style="text-align: center">
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <img src="../assets/about_tb1.jpg" />
                </el-row>
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <div class="menuwz"><a href="#11">公司简介</a></div>
                </el-row>
              </el-col>
              <el-col :span="4" style="text-align: center">
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <img src="../assets/about_tb2.jpg" />
                </el-row>
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <div class="menuwz"><a href="#22">发展历程</a></div>
                </el-row>
              </el-col>
              <el-col :span="4" style="text-align: center">
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <img src="../assets/about_tb3.jpg" />
                </el-row>
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <div class="menuwz"><a href="#33">荣誉资质</a></div>
                </el-row>
              </el-col>
              <el-col :span="4" style="text-align: center">
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <img src="../assets/about_tb4.jpg" />
                </el-row>
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <div class="menuwz"><a href="#55">企业文化</a></div>
                </el-row>
              </el-col>
              <el-col :span="7">
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <el-col :span="18">
                    <div class="menuwz">
                      <router-link to="/" style="float: right"
                        >返回首页</router-link
                      >
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <img src="../assets/return.jpg" />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple"></div>
        </el-col>
      </el-row> -->
      <!-- 公司简介 -->
      <el-row class="gsjjbg" id="1">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row>
              <el-col :span="24">
                <div style="text-align: center; margin-top: 30px">
                  <div class="bt" id="aboutcs">关于仓实</div>
                  <div class="fbt">about us</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
            <div class="wenben" style="margin-top: 3%">
              <span style="font-weight: bolder; font-size: 30px">仓实</span
              >信息科技有限公司是一家致力于推动空间地理技术服务的科技型企业，为国家科技部科技型中小入库企业的一员，拥有多项发明专利及行业产品著作权，连续多年获得“广东省守合同重信用企业”的认定，2022年被评为国家高新技术企业。
            </div>
            <div class="wenben">
              公司专业从事政府规划、自然资源、住房建设部门、房地产企业、国有资产管理企业等相关单位的信息化建设咨询、应用平台构建、数据资源挖掘与分析及业务过程监理。先后为多个政府单位、企业提供关于城市更新、不动产登记、房屋安全鉴定、物业管理、空间安防、乡村振兴等信息化服务，多次获得用户好评。
            </div>
            <div class="wenben">
              公司具有高效的内部管理制度。优良的工作环境以及良好的激励机制，吸引了一批有学识的、具有实干精神的专业人才。高素质、高水平、高效率的服务宗旨让仓实信息在当下科技型企业中立于领先地位。
            </div>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 3%"></el-row>
        <!-- <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple-light"></div>
        </el-col>
        <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
          <el-row>
            <div style="margin-top: 30px"></div>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-row>
                <el-col :span="1">
                  <div class="grid-content bg-purple">
                    <div class="nbtzx1"></div>
                  </div>
                </el-col>
                <el-col :span="23">
                  <el-row>
                    <div class="nbt" id="11">公司简介</div>
                  </el-row>
                  <el-row>
                    <div class="nfbt">有目标有追求</div>
                  </el-row>
                </el-col>
              </el-row>
              <el-row>
                <div class="wenben">
                  广州仓实信息科技有限公司是一家致力于推动空间地理技术服务的科技型企业，为国家科技部科技型中小入库企业的一员，拥有多项发明专利及行业产品著作权，连续多年获得“广东省守合同重信用企业”的认定，2022年被评为国家高新技术企业。公司专业从事政府规划、自然资源、住房建设部门、房地产企业、国有资产管理企业等相关单位的信息化建设咨询、应用平台构建、数据资源挖掘与分析及业务过程监理。先后为多个政府单位、企业提供关于<span
                    style="font-weight: bolder"
                    >城市更新、不动产登记、房屋安全鉴定、物业管理、空间安防、乡村振兴</span
                  >等信息化服务，多次获得用户好评。公司具有高效的内部管理制度。优良的工作环境以及良好的激励机制，吸引了一批有学识的、具有实干精神的专业人才。高素质、高水平、高效率的服务宗旨让仓实信息在当下科技型企业中立于领先地位。
                </div>
              </el-row>
            </el-col>
            <el-col :span="12">
              <img src="../assets/about_xx.jpg" width="100%" />
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple"></div>
        </el-col> -->
      </el-row>
      <!-- 发展历程 -->
      <el-row class="lichenbg" id="2">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row>
              <el-col :span="24">
                <div style="text-align: center; margin-top: 30px">
                  <div class="bt" id="course">发展历程</div>
                  <div class="fbt">development course</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
            <el-row>
              <el-col :span="24">
                <el-timeline>
                  <el-timeline-item timestamp="2018" placement="top">
                    <el-row>
                      <div class="fazhanwz">广州仓实信息科技有限公司成立</div>
                      <div class="fazhanwz">
                        公司城市更新产品V1.0正式推向房企市场
                      </div>
                    </el-row>
                  </el-timeline-item>
                  <el-timeline-item timestamp="2019" placement="top">
                    <el-row>
                      <div class="fazhanwz">
                        佛山公司成立，拓展大湾区范围业务
                      </div>
                      <div class="fazhanwz">
                        公司城市更新产品V1.0正式推向房企市场
                      </div>
                    </el-row>
                  </el-timeline-item>
                  <el-timeline-item timestamp="2020" placement="top">
                    <el-row>
                      <div class="fazhanwz">
                        广州大数据工程及项目监理公司（合时）成立
                      </div>
                      <div class="fazhanwz">
                        城市更新产品V3.5面世，与多个大型房企业深度合作实现软件、数据、服务多方面盈利增长
                      </div>
                    </el-row>
                  </el-timeline-item>
                  <el-timeline-item timestamp="2021" placement="top">
                    <el-row>
                      <div class="fazhanwz">
                        评为国家级“高新技术企业”连续四年入选国家科技部中小入库企业
                      </div>
                      <div class="fazhanwz">
                        城市更新系更产品5.0面世，覆盖粤港大湾区90%项目振兴乡村，智慧消防、智慧物业、档案AI智能化等产品相继面世及推广落地
                      </div>
                      <div class="fazhanwz">
                        公司市场、业务范围、盈利迈向高速增长
                      </div>
                    </el-row>
                  </el-timeline-item>
                </el-timeline>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row> -->

        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
            <TimeLine :items="timelineItems"></TimeLine>
            <!-- <timeline></timeline> -->
            <!-- <el-row style="margin-top: 5%">
              <el-col :span="24">
                <el-row type="flex">
                  <img src="../assets/licheng.png" height="100%" />
                  <el-row style="margin-left: 3%">
                    <div class="fazhanbt">未来目标</div>
                    <div class="fazhanwz">
                      以基于空间地理信息技术服务为基础融合产业未来发展进行布局
                    </div>
                    <div class="fazhanwz">
                      顺未来之势、周期之势、国家发展全局之势成为未来世界领军
                    </div>
                  </el-row>
                </el-row>
                <el-row type="flex">
                  <img src="../assets/licheng.png" height="100%" />
                  <el-row style="margin-left: 3%">
                    <div class="fazhanbt">2022</div>
                    <div class="fazhanwz">被评为国家高新技术企业</div>
                    <div class="fazhanwz">公司业务持续快速增长</div>
                  </el-row>
                </el-row>
                <el-row type="flex">
                  <img src="../assets/licheng.png" height="100%" />
                  <el-row style="margin-left: 3%">
                    <div class="fazhanbt">2021</div>
                    <div class="fazhanwz">
                      评为国家级“高新技术企业”连续四年入选国家科技部中小入库企业
                    </div>
                    <div class="fazhanwz">
                      城市更新系更产品5.0面世，覆盖粤港大湾区90%项目，振兴乡村、智慧消防、智慧物业、档案AI智能化等产品相继面世及推广落地，信创项目启动
                    </div>
                    <div class="fazhanwz">与PWC、宏江成为战略合作伙伴</div>
                    <div class="fazhanwz">公司业务迈向高速增长</div>
                  </el-row>
                </el-row>
                <el-row type="flex">
                  <img src="../assets/licheng.png" height="100%" />
                  <el-row style="margin-left: 3%">
                    <div class="fazhanbt">2020</div>
                    <div class="fazhanwz">广州大数据工程及项目监理公司成立</div>
                    <div class="fazhanwz">
                      城市更新产品V3.5面世，与多个大型房企业深度合作，实现软件、数据、服务多方面营业增长
                    </div>
                  </el-row>
                </el-row>
                <el-row type="flex">
                  <img src="../assets/licheng.png" height="100%" />
                  <el-row style="margin-left: 3%">
                    <div class="fazhanbt">2019</div>
                    <div class="fazhanwz">佛山公司成立，拓展大湾区范围业务</div>
                    <div class="fazhanwz">
                      城市更新产品V2.0面世，推广致政府部门
                    </div>
                  </el-row>
                </el-row>
                <el-row type="flex">
                  <img src="../assets/licheng.png" height="100%" />
                  <el-row style="margin-left: 3%">
                    <div class="fazhanbt">2018</div>
                    <div class="fazhanwz">广州仓实信息科技有限公司成立</div>
                    <div class="fazhanwz">
                      公司城市更新产品V1.0正式推向房企市场
                    </div>
                  </el-row>
                </el-row>
              </el-col>
            </el-row> -->
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row>
      </el-row>
      <!-- 荣誉资质 -->
      <!-- <el-row class="rongyubg" id="3">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
            <el-row>
              <el-col :span="24">
                <div style="text-align: center; margin-top: 30px">
                  <div class="bt">荣誉资质</div>
                  <div class="fbt">我们与日俱进</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5%"></el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
            <el-row style="text-align: center">
              <div style="font-size: 20px; line-height: 40px">
                <i class="el-icon-star-on"></i>
                2019年-2022年连续四年被评定国家科技部科技型中小入库企业
              </div>
              <div style="font-size: 20px; line-height: 40px">
                <i class="el-icon-star-on"></i>
                国家高新技术企业认证
              </div>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="12">
                <el-row>
                  <div class="copyright-title">十余项软件产品著作版权</div>
                  <ul class="copyrihgt-contact">
                    <li v-for="(item, index) in copyrightList" :key="index">
                      {{ item.copyright }}
                    </li>
                  </ul>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <div class="copyright-title">发明专利</div>
                  <ul class="copyrihgt-contact">
                    <li v-for="(item, index) in patentList" :key="index">
                      {{ item.patent }}
                    </li>
                  </ul>
                </el-row>
              </el-col>
            </el-row>
            <el-row>
              <div class="honor-title">企业荣誉</div>
            </el-row>
            <el-row :gutter="20" style="margin-top: 20px">
              <el-col :span="6">
                <el-row
                  style="
                    text-align: center;
                    background-color: #fff;
                    padding-top: 20px;
                    border: 5px solid #eeeeee;
                  "
                >
                  <el-row>
                    <img src="../assets/zili_yb.jpg" width="70%" />
                  </el-row>
                  <el-row style="font-size: 16px; line-height: 40px"
                    >中国政府采购信用企业</el-row
                  >
                </el-row>
              </el-col>
              <el-col :span="6">
                <el-row
                  style="
                    text-align: center;
                    background-color: #fff;
                    padding-top: 20px;
                    border: 5px solid #eeeeee;
                  "
                >
                  <el-row>
                    <img src="../assets/zili_yb2.png" width="70%" />
                  </el-row>
                  <el-row style="font-size: 16px; line-height: 40px"
                    >信用中国AAA认证单位</el-row
                  >
                </el-row>
              </el-col>
              <el-col :span="6">
                <el-row
                  style="
                    text-align: center;
                    background-color: #fff;
                    padding-top: 20px;
                    border: 5px solid #eeeeee;
                  "
                >
                  <el-row>
                    <img src="../assets/zili_yb.jpg" width="70%" />
                  </el-row>
                  <el-row style="font-size: 16px; line-height: 40px"
                    >广东省“守合同重信用”企业</el-row
                  >
                </el-row>
              </el-col>
              <el-col :span="6">
                <el-row
                  style="
                    text-align: center;
                    background-color: #fff;
                    padding-top: 20px;
                    border: 5px solid #eeeeee;
                  "
                >
                  <el-row>
                    <img src="../assets/zili_yb.jpg" width="70%" />
                  </el-row>
                  <el-row style="font-size: 16px; line-height: 40px"
                    >A级纳税信用认证单位</el-row
                  >
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5%"></el-row>
      </el-row> -->
      <!-- 企业文化 -->
      <el-row id="4" style="background-color: #fbfbfb">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row>
              <el-col :span="24">
                <div style="text-align: center; margin-top: 30px">
                  <div class="bt" id="culture">企业文化</div>
                  <div class="fbt">corporate culture</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="16">
            <el-row style="margin-top: 3%"></el-row>
            <el-row align="middle" type="flex">
              <el-col :span="12">
                <el-row style="padding: 40px">
                  <el-row>
                    <img src="../assets/jt-dh_01.png" />
                  </el-row>
                  <el-row>
                    <div style="margin-top: 10px"></div>
                  </el-row>
                  <el-row>
                    <div class="wenhuawz">专业、务实、创新、高效</div>
                    <div class="wenhuanr">
                      专业出品质，务实促稳定，创新促发展，高效出财富
                    </div>
                  </el-row>
                  <el-row>
                    <div class="wenhuawz">诚信立业、仓廪实而知礼节</div>
                    <div class="wenhuanr">
                      以诚信为事业基础，收获伙伴支持肯定
                    </div>
                  </el-row>
                  <el-row>
                    <div class="wenhuawz">仓实—CHANCE</div>
                    <div class="wenhuanr">一个互相成就的机会</div>
                  </el-row>
                  <el-row>
                    <div class="wenhuawz">MAD</div>
                    <div class="wenhuanr">Make A Difference改变不止一点点</div>
                  </el-row>
                  <el-row style="float: right">
                    <img src="../assets/jt-dh_02.png" />
                  </el-row>
                </el-row>
              </el-col>
              <el-col :span="12">
                <img src="../assets/wenhua_1.jpg" width="100%" />
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-top: 5%"></div>
        </el-row>
      </el-row>
      <div class="foot">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="20">
            <el-row style="margin-top: 30px"></el-row>
            <el-row style="display: flex; align-items: center">
              <el-col :xs="4" :sm="4" :md="3" :lg="3" :xl="3">
                <img src="../assets/logo-foot.png" width="100%" />
              </el-col>
              <el-col :xs="20" :sm="20" :md="21" :lg="21" :xl="21">
                <el-row style="margin-left: 150px">
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">公司介绍</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row>
                        <a class="aboutitem" href="#aboutcs">关于仓实</a>
                      </el-row>
                      <el-row>
                        <a class="aboutitem" href="#course"> 公司历程 </a>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpyewu">核心业务</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpzizhi">公司资质</div>
                      </el-row>
                      <el-row>
                        <a class="aboutitem" href="#culture"> 企业文化 </a>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">公司产品</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpgov">
                          政府信息化应用平台
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpgov">
                          政府行业技术服务
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpent">
                          企业信息化应用平台
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpent">
                          企业行业技术服务
                        </div>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">项目案例</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumphejin">合景泰富</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpbudongchan">
                          不动产信息建设
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpshidai">
                          时代中国
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpkaide">凯德中国</div>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">联系我们</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb1.png"
                          width="20px"
                          height="18px"
                        />
                        <div class="aboutitem">020-89884234</div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb2.png"
                          width="20px"
                          height="20px"
                        />
                        <div class="aboutitem">
                          zhongweiming@cangshigroup.com
                        </div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb3.png"
                          width="20px"
                          height="16px"
                        />
                        <div class="aboutitem">邮政编码:510000</div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb4.png"
                          width="20px"
                          height="21px"
                        />
                        <div class="aboutitem">
                          广州市越秀区广卫路19-2金科大厦6楼
                        </div>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-top: 20px"></div>
        </el-row>
        <el-row>
          <div
            style="
              text-align: center;
              color: #ccc;
              line-height: 30px;
              font-size: 14px;
              font-weight: lighter;
              border-top: 1px solid #222634;
            "
          >
            CopyRight © 2022 All Right Reserved 广州仓实信息科技有限公司
            粤ICP备07032789号
          </div>
        </el-row>
      </div>
      <back-top />
      <el-backtop
        target=".container"
        :bottom="30"
        :right="50"
        :visibility-height="400"
      >
        <div
          style="
             {
              height: 100%;
              width: 100%;
              background-color: #ff8041;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
              text-align: center;
              line-height: 40px;
              color: #ffffff;
              border-radius: 50px;
              align-items: center;
              text-align: center;
            }
          "
        >
          <i class="el-icon-caret-top"></i>
        </div>
      </el-backtop>
    </div>
    <!-- 移动端 -->
    <div class="mobileshow" v-if="mobilecentenshow">
      <el-row
        style="display: flex; align-items: center; padding: 10px 10px 10px 10px"
      >
        <el-col :span="20">
          <div @click="backhome">
            <img src="../assets/cslogo-mb.png" style="width: 100%" />
          </div>
        </el-col>
        <el-col :span="4" style="text-align: end">
          <div @click="hangdleclick = true">
            <img src="../assets/menu_b.png" style="width: 50%" />
          </div>
        </el-col>
      </el-row>

      <el-drawer
        title=""
        :visible.sync="hangdleclick"
        :direction="direction"
        size="60%"
        :overflow-y="hidden"
      >
        <el-row>
          <el-menu
            default-active="/CsIntroduce"
            router
            active-text-color="#ff8041"
          >
            <el-menu-item index="/">
              <template slot="title">
                <div @click="hangdleclick = false" style="padding: 0 0 0 10px">
                  <i class="el-icon-house" style="font-weight: bolder"></i>
                  <span class="navtb">首页</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/CsIntroduce">
              <template slot="title">
                <div
                  style="
                    padding: 0 0 0 10px;
                    border-radius: 10px;
                    background-color: #eeeeee;
                  "
                >
                  <i
                    class="el-icon-office-building"
                    style="font-weight: bolder"
                  ></i>
                  <span class="navtb">公司介绍</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/Product">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i class="el-icon-box" style="font-weight: bolder"></i>
                  <span class="navtb">产品领域</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/Project">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i
                    class="el-icon-data-analysis"
                    style="font-weight: bolder"
                  ></i>
                  <span class="navtb">项目案例</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/Contact">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i class="el-icon-message" style="font-weight: bolder"></i>
                  <span class="navtb">联系我们</span>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-row>
      </el-drawer>
      <el-row>
        <img src="../assets/mb_about_bg.jpg" width="100%" />
      </el-row>
      <!-- <el-row style="text-align: center">
        <el-col :span="6">
          <div><a href="#11" class="abwz">公司简介</a></div>
        </el-col>
        <el-col :span="6">
          <div><a href="#22" class="abwz">发展历程</a></div>
        </el-col>
        <el-col :span="6">
          <div><a href="#33" class="abwz">荣誉资质</a></div>
        </el-col>
        <el-col :span="6">
          <div><a href="#44" class="abwz">企业文化</a></div>
        </el-col>
      </el-row> -->
      <!-- 移动端公司简介 -->
      <el-row style="background: #f7f7f7">
        <el-row>
          <el-row>
            <el-col :span="24">
              <div class="tltle">
                <p class="ntz" id="11">关于仓实</p>
                <p class="nxd">about us</p>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple-light"></div>
            </el-col>
            <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
              <div class="aboutwz">
                仓实信息科技有限公司是一家致力于推动空间地理技术服务的科技型企业，为国家科技部科技型中小入库企业的一员，拥有多项发明专利及行业产品著作权，连续多年获得“广东省守合同重信用企业”的认定，2022年被评为国家高新技术企业。
              </div>
              <div class="aboutwz">
                公司专业从事政府规划、自然资源、住房建设部门、房地产企业、国有资产管理企业等相关单位的信息化建设咨询、应用平台构建、数据资源挖掘与分析及业务过程监理。先后为多个政府单位、企业提供关于<span
                  style="font-weight: bolder"
                  >城市更新、不动产登记、物管安防，乡村振兴，空间数据资源建库</span
                >等地理信息系统的开发及数据处理服务，多次获得用户好评。
              </div>
              <div class="aboutwz">
                公司具有高效的内部管理制度。优良的工作环境以及良好的激励机制，吸引了一批有学识的、具有实干精神的专业人才。高素质、高水平、高效率的服务宗旨让仓实信息在当下科技型企业中立于领先地位。
              </div>
            </el-col>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5%"></el-row>
          <el-row>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple-light"></div>
            </el-col>
            <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
              <img src="../assets/about_1.jpg" width="100%" />
            </el-col>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10%"></el-row>
        </el-row>
      </el-row>
      <!-- 移动端公司历程 -->
      <el-row>
        <el-row>
          <el-row>
            <el-col :span="24">
              <div class="tltle">
                <p class="ntz" id="22">发展历程</p>
                <p class="nxd">development course</p>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10%"></el-row>
          <el-row>
            <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2">
              <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="20">
              <TimeLinemb :items="Items"></TimeLinemb>

              <!-- <el-row>
                <el-timeline reverse="true">
                  <el-timeline-item timestamp="2018" placement="top">
                    <div style="font-size: 1.6rem" class="fazhanwz">
                      广州仓实信息科技有限公司成立
                    </div>
                    <div style="font-size: 1.6rem" class="fazhanwz">
                      公司城市更新产品V1.0正式推向房企市场
                    </div>
                    <img src="../assets/96.jpg" width="100%" />
                  </el-timeline-item>
                  <el-timeline-item timestamp="2019" placement="top">
                    <div style="font-size: 1.6rem" class="fazhanwz">
                      佛山公司成立，拓展大湾区范围业务
                    </div>
                    <div style="font-size: 1.6rem" class="fazhanwz">
                      城市更新产品V2.0面世，推广致政府部门
                    </div>
                    <img src="../assets/97.jpg" width="100%" />
                  </el-timeline-item>
                  <el-timeline-item timestamp="2020" placement="top">
                    <div style="font-size: 1.6rem" class="fazhanwz">
                      广州大数据工程及项目监理公司成立
                    </div>
                    <div style="font-size: 1.6rem" class="fazhanwz">
                      城市更新产品V3.5面世，与多个大型房企业深度合作，实现软件、数据、服务多方面营业增长
                    </div>
                    <img src="../assets/95.jpg" width="100%" />
                  </el-timeline-item>
                  <el-timeline-item timestamp="2021" placement="top">
                    <div style="font-size: 1.6rem" class="fazhanwz">
                      评为国家级“高新技术企业”连续四年入选国家科技部中小入库企业
                    </div>
                    <div style="font-size: 1.6rem" class="fazhanwz">
                      城市更新系更产品5.0面世，覆盖粤港大湾区90%项目，振兴乡村、智慧消防、智慧物业、档案AI智能化等产品相继面世及推广落地，信创项目启动
                    </div>
                    <div style="font-size: 1.6rem" class="fazhanwz">
                      与PWC、宏江成为战略合作伙伴
                    </div>
                    <div style="font-size: 1.6rem" class="fazhanwz">
                      公司业务迈向高速增长
                    </div>
                    <img src="../assets/98.jpg" width="100%" />
                  </el-timeline-item>
                  <el-timeline-item timestamp="2022" placement="top">
                    <div style="font-size: 1.6rem" class="fazhanwz">
                      被评为国家高新技术企业
                    </div>
                    <div style="font-size: 1.6rem" class="fazhanwz">
                      公司业务持续快速增长
                    </div>
                    <img src="../assets/99.jpg" width="100%" />
                  </el-timeline-item>
                </el-timeline>
              </el-row> -->
            </el-col>
            <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2">
              <div class="grid-content bg-purple"></div>
            </el-col>
          </el-row>
        </el-row>
        <el-row style="margin-top: 3%"></el-row>
      </el-row>

      <!-- 移动端企业文化 -->
      <el-row style="background: #f7f7f7">
        <el-row>
          <el-row>
            <el-col :span="24">
              <div class="tltle">
                <p class="ntz" id="44">企业文化</p>
                <p class="nxd">corporate culture</p>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5%"></el-row>
          <el-row>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
              <el-row>
                <img src="../assets/qywh.jpg" width="100%" />
              </el-row>
            </el-col>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
              <el-row>
                <div v-for="(item, index) in cultureList" :key="index">
                  <div class="qywhzbt">{{ item.zbt }}</div>
                  <div class="qywhfbt">{{ item.fbt }}</div>
                </div>
              </el-row>
            </el-col>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
          </el-row>
        </el-row>
        <el-row style="margin-top: 10%"></el-row>
      </el-row>
      <!-- 移动端底部 -->
      <div style="background-color: #1b2033">
        <el-row>
          <el-col :xs="2" :sm="2" :md="4" :lg="4" :xl="5">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="20" :sm="20" :md="16" :lg="16" :xl="14">
            <el-row style="margin-top: 30px">
              <img src="../assets/logo-footmb.png" width="50%" />
            </el-row>
            <el-row style="margin-top: 30px">
              <el-col :span="10">
                <router-link to="/CsIntroduce" class="footwz_mb"
                  >关于仓实</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
              <el-col :span="14">
                <router-link to="/Product" class="footwz_mb"
                  >公司产品</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 20px">
              <el-col :span="10">
                <router-link to="/Project" class="footwz_mb"
                  >项目案例</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
              <el-col :span="14">
                <router-link to="/Contact" class="footwz_mb"
                  >联系我们</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row
              style="color: #a6a7a9; font-size: 12px; font-weight: lighter"
            >
              <el-row> CopyRight © 2022 All Right Reserved </el-row>
              <el-row> 广州仓实信息科技有限公司 </el-row>
              <el-row> 粤ICP备07032789号 </el-row>
            </el-row>
            <!-- <el-row type="flex" justify="center" class="footnav">
              <el-col :span="6" style="border-right: 1px solid #a6a7a9">
                <router-link to="/">首页</router-link>
              </el-col>
              <el-col :span="6" style="border-right: 1px solid #a6a7a9">
                <router-link to="/CsIntroduce">公司介绍</router-link>
              </el-col>
              <el-col :span="6" style="border-right: 1px solid #a6a7a9">
                <router-link to="/Project">项目案例</router-link>
              </el-col>
              <el-col :span="6">
                <router-link to="/Contact">联系我们</router-link>
              </el-col>
            </el-row> -->
            <el-row>
              <div style="margin-top: 3%"></div>
            </el-row>
          </el-col>
          <el-col :xs="2" :sm="2" :md="4" :lg="4" :xl="5">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>

        <!-- <el-row justify="center" type="flex">
          <el-col :span="5">
            <div style="width: 20px"></div>
          </el-col>
          <el-col :span="14">
            <div class="banqz">
              CopyRight 2022 All Right Reserved <br />广州仓实信息科技有限公司
              粤ICP备07032789号
            </div>
          </el-col>
          <el-col :span="5">
            <div style="width: 20px"></div>
          </el-col>
        </el-row> -->
        <el-row>
          <div style="margin-bottom: 3%"></div>
        </el-row>
      </div>
      <el-backtop
        target=".container"
        :bottom="15"
        :right="15"
        :visibility-height="30"
      >
        <div
          style="
             {
              height: 100%;
              width: 100%;
              background-color: #ff8041;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
              text-align: center;
              line-height: 40px;
              color: #ffffff;
              border-radius: 50px;
              align-items: center;
              text-align: center;
            }
          "
        >
          <i class="el-icon-caret-top"></i>
        </div>
      </el-backtop>
    </div>
  </div>
</template>

<script>
import backTop from "@/components/backTop.vue";
import TimeLine from "@/components/TimeLine-test.vue";
import TimeLinemb from "@/components/TimeLine-test-mb.vue";
// import timeline from "@/components/Timeline.vue";
// import Timeline from "@/components/Timeline-1.vue";
export default {
  components: { backTop, TimeLine, TimeLinemb },
  data() {
    return {
      screenWidth: 0,
      screenHeight: 0,
      nvabar1show: true,
      nvabar2show: false,
      pccentenshow: true,
      mobilecentenshow: false,
      mobileshow: true,
      hangdleclick: false,
      input: "",
      cultureList: [
        {
          zbt: "专业、务实、创新、高效",
          fbt: "专业出品质，务实促稳定，创新促发展，高效出财富",
        },
        {
          zbt: "诚信立业、仓廪实而知礼节",
          fbt: "以诚信为事业基础，收获伙伴支持肯定",
        },
        {
          zbt: "仓实—CHANCE",
          fbt: "一个互相成就的机会",
        },
        {
          zbt: "MAD",
          fbt: "Make A Difference改变不止一点点",
        },
      ],
      timelineItems: [
        {
          time: "未来目标",
          content1: "以基于空间地理信息技术服务为基础融合产业未来发展进行布局",
          content2: "顺未来之势、周期之势、国家发展全局之势成为未来世界领军",
        },
        {
          time: "2022年",
          content1: "被评为国家高新技术企业",
          content2: "公司业务持续快速增长",
        },
        {
          time: "2021年",
          content1:
            "评为国家级“高新技术企业”连续四年入选国家科技部中小入库企业",
          content2:
            "城市更新系更产品5.0面世，覆盖粤港大湾区90%项目，振兴乡村、智慧消防、智慧物业、档案AI智能化等产品相继面世及推广落地，信创项目启动与PWC、宏江成为战略合作伙伴",
          content3: "公司业务迈向高速增长",
        },
        {
          time: "2020年",
          content1: "广州大数据工程及项目监理公司成立",
          content2:
            "城市更新产品V3.5面世，与多个大型房企业深度合作，实现软件、数据、服务多方面营业增长",
        },
        {
          time: "2019年",
          content1: "佛山公司成立，拓展大湾区范围业务",
          content2: "城市更新产品V2.0面世，推广致政府部门",
        },
        {
          time: "2018年",
          content1: "广州仓实信息科技有限公司成立",
          content2: "公司城市更新产品V1.0正式推向房企市场",
        },
      ],
      Items: [
        {
          time: "未来目标",
          content1: "以基于空间地理信息技术服务为基础融合产业未来发展进行布局",
          content2: "顺未来之势、周期之势、国家发展全局之势成为未来世界领军",
        },
        {
          time: "2022年",
          content1: "被评为国家高新技术企业",
          content2: "公司业务持续快速增长",
        },
        {
          time: "2021年",
          content1:
            "评为国家级“高新技术企业”连续四年入选国家科技部中小入库企业",
          content2:
            "城市更新系更产品5.0面世，覆盖粤港大湾区90%项目，振兴乡村、智慧消防、智慧物业、档案AI智能化等产品相继面世及推广落地，信创项目启动与PWC、宏江成为战略合作伙伴",
          content3: "公司业务迈向高速增长",
        },
        {
          time: "2020年",
          content1: "广州大数据工程及项目监理公司成立",
          content2:
            "城市更新产品V3.5面世，与多个大型房企业深度合作，实现软件、数据、服务多方面营业增长",
        },
        {
          time: "2019年",
          content1: "佛山公司成立，拓展大湾区范围业务",
          content2: "城市更新产品V2.0面世，推广致政府部门",
        },
        {
          time: "2018年",
          content1: "广州仓实信息科技有限公司成立",
          content2: "公司城市更新产品V1.0正式推向房企市场",
        },
      ],
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; // 实时宽度
        window.screenHeight = document.documentElement.clientHeight; // 实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
        that.hangdlenvabarshow();
        that.hangdlepsshow();
      })();
    };

    // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    window.screenWidth = document.documentElement.clientWidth; // 实时宽度
    window.screenHeight = document.documentElement.clientHeight; // 实时高度
    this.screenWidth = window.screenWidth;
    this.screenHeight = window.screenHeight;
    this.hangdlenvabarshow();
    this.hangdlepsshow();
    this.$nextTick(() => {
      this.toLocal();
    });
  },
  //用完后记得将存储的锚点置空，否则会影响其他页面跳转到当前页面
  destroyed() {
    localStorage.setItem("cec", "");
  },
  methods: {
    hangdlenvabarshow() {
      if (this.screenWidth > 1080) {
        this.nvabar1show = true;
        // this.$refs.navbar1.style.display = 'none'
        // this.nvabar2show = true;
      } else {
        if (this.screenWidth < 1080) {
          this.nvabar1show = false;
        }
        // if (this.screenWidth >= 979) {
        //   this.nvabar1show = true;
        //   this.nvabar2show = false;
        // }
      }
    },
    hangdlepsshow() {
      if (this.screenWidth > 1080) {
        this.pccentenshow = true;
        this.mobilecentenshow = false;
      } else {
        this.pccentenshow = false;
        this.mobilecentenshow = true;
      }
    },
    backhome() {
      this.$router.push("/");
    },
    Gocontact() {
      this.$router.push("/Contact");
    },
    jumpyewu() {
      localStorage.setItem("cec1", "yewu");
      this.$router.push({ path: "/" });
    },
    jumpzizhi() {
      localStorage.setItem("cec1", "zizhi");
      this.$router.push({ path: "/" });
    },
    jumpgov() {
      localStorage.setItem("cec2", "gov");
      this.$router.push({ path: "/Product" });
    },
    jumpent() {
      localStorage.setItem("cec2", "ent");
      this.$router.push({ path: "/Product" });
    },
    jumphejin() {
      localStorage.setItem("cec3", "hejin");
      this.$router.push({ path: "/Project" });
    },
    jumpbudongchan() {
      localStorage.setItem("cec3", "budongchan");
      this.$router.push({ path: "/Project" });
    },
    jumpshidai() {
      localStorage.setItem("cec3", "shidai");
      this.$router.push({ path: "/Project" });
    },
    jumpkaide() {
      localStorage.setItem("cec3", "kaide");
      this.$router.push({ path: "/Project" });
    },
    toLocal() {
      //查找存储的锚点id
      let Id = localStorage.getItem("cec");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding: 0;
  height: 100%;
  // overflow-y: auto;
  overflow-x: hidden;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  border: 0px;
  background-color: #fff;
  box-shadow: 0 0 13px 0 rgb(0 0 0 / 14%);
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 0;
}

.navbar-header {
  align-items: center;
  display: flex;
}

.navbar-nav {
  font-size: 18px;
  padding: 10px;
}

.nbtzx {
  height: 41px;
  border-left: #888888 1px solid;
  float: left;
  padding-left: 10px;
}

.navwz {
  position: relative;
  box-sizing: border-box;
}

.navwz::after {
  content: "";
  width: 0;
  height: 3px;
  background: #ff8041;
  position: absolute;
  left: 50%;
  transition: all 0.4s;
  bottom: -10px;
}

.navwz:hover::after {
  left: 0;
  width: 100%;
  bottom: -10px;
}

.xl {
  position: relative;
  box-sizing: border-box;
}

.xl::after {
  content: "";
  width: 0;
  height: 3px;
  background: #ff8041;
  position: absolute;
  left: 50%;
  transition: all 0.4s;
}

.xl:hover::after {
  left: 0;
  width: 100%;
}

.navwz {
  color: #0381ca;
  text-decoration: none;
  font-weight: bolder;
}

.navwz:hover {
  color: #ff8041;
  text-decoration: none;
  font-weight: bolder;
}

// .navleft {
//   padding: 20px 0 20px 20px;
//   align-items: center;
//   display: flex;
// }

.navright {
  padding: 20px 20px 20px 0;
  text-align: center;
  font-size: 20px;
  display: flex;
}

.bt {
  font-size: 35px;
  line-height: 45px;
  // font-weight: bold;
  padding-bottom: 0px;
}

.fbt {
  // float: left;
  line-height: 25px;
  // color: #999;
  text-transform: uppercase;
  padding-top: 5px;
  font-size: 20px;
}

// 公司简介
.menuwz a {
  font-size: 18px;
  line-height: 40px;
  color: black;
  text-decoration: none;
}

.menuwz a:hover {
  color: #ff8041;
  line-height: 40px;
  text-decoration: none;
}

.menubg {
  background: url("../assets/about_bg.jpg") no-repeat center;
}

.nbtzx1 {
  height: 80px;
  border-left: #ff8041 5px solid;
  float: left;
  padding-left: 10px;
}

.nbt {
  font-size: 40px;
  line-height: 45px;
  font-weight: bold;
  padding-bottom: 0px;
}

.nfbt {
  float: left;
  line-height: 25px;
  color: #999;
  text-transform: uppercase;
  padding-top: 5px;
  font-size: 16px;
}

.wenben {
  float: left;
  line-height: 35px;
  color: #666;
  padding-top: 10px;
  font-size: 16px;
}

.fazhanbt {
  font-size: 40px;
  color: #ff8041;
  font-weight: bold;
  // margin: 40px 0 0 30px;
}

.fazhanwz {
  color: #333;
  line-height: 35px;
  font-size: 1.8rem;
  // padding-right: 55px;
}

.zilibj {
  text-align: center;
  padding: 40px;
  border: 5px solid #eeeeee;
  background-color: #ffffff;
}

.wenhuawz {
  margin-top: 20px;
  font-weight: bolder;
  font-style: italic;
  line-height: 40px;
  font-size: 20px;
  color: #333333;
}
.wenhuanr {
  font-size: 16px;
  line-height: 30px;
  color: #333333;
}

.foot {
  // width: 1920px;
  // height: 235px;
  background: #1b2033;
  margin-top: 0px;
  clear: both;
}

.about {
  // margin-top: 15%;
  clear: both;
  line-height: 35px;
  color: #ccc;
  font-size: 23px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}

.about::after {
  content: "";
  width: 0px;
  height: 3px;
  background: #ff8041;
  position: absolute;
  left: 0;
  transition: all 0.4s;
  bottom: -5px;
}

.about:hover::after {
  left: -1;
  width: 15%;
  bottom: -5px;
}

.aboutline {
  width: 35px;
  height: 5px;
  // border-bottom: 3px #ff8041 solid;
  margin-bottom: 10px;
}

.aboutitem {
  clear: both;
  line-height: 35px;
  color: #ccc;
  font-size: 15px;
  white-space: nowrap;
  margin-left: 2%;
  cursor: pointer;
  transition: color 0.4s ease;
  text-decoration: none;
}
.aboutitem:hover {
  color: #ff8041;
  // transform: translateX(-5px);
}

.gsjjbg {
  background: url("../assets/about_bj2.jpg") no-repeat center;
  background-size: cover;
}

.lichenbg {
  background: url("../assets/fazhan_bg.jpg") no-repeat center;
  background-size: cover;
}

.rongyubg {
  background: url("../assets/bolan.jpg") center;
  // background-size: cover;
}

// 移动端
.abwz {
  color: #333;
  text-align: center;
  line-height: 35px;
}
.abwz {
  text-decoration: none;
  color: #333;
}

.ntz {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 2.2rem;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
}

.nxd {
  width: 100%;
  text-align: center;
  color: #999;
  font-size: 1.4rem;
  width: 100%;
  // margin-top: 5px;
  text-transform: uppercase;
}

.aboutwz {
  text-indent: 2em;
  line-height: 30px;
  font-size: 1.6rem;
  margin-top: 1rem;
}

.lichenwz {
  color: #ff8041;
  font-size: 20px;
  font-weight: bold;
}

.lichenjs {
  line-height: 30px;
  font-size: 18px;
  margin-bottom: 5px;
}

.zilibg {
  background: #ffffff;
  text-align: center;
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 8px solid #f7f7f7;
}

.qywhwz {
  font-size: 18px;
  line-height: 30px;
  margin-top: 10px;
}

.footnav {
  text-align: center;
}

.footnav a {
  font-size: 14px;
  color: #fff;
}

.banqz {
  width: 100%;
  text-align: center;
  color: #a6a7a9;
  font-size: 12px;
  background-color: #10131f;
}
.copyright-title {
  font-size: 18px;
  background-color: #404040;
  color: #ffffff;
  padding: 10px;
  line-height: 25px;
  text-align: center;
  margin-top: 20px;
  font-weight: bolder;
}
.copyrihgt-contact {
  list-style: none;
  text-align: left;
  font-size: 16px;
  line-height: 25px;
}
.el-icon-star-on {
  color: #ff8041;
  font-weight: bolder;
}
.honor-title {
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
  margin-top: 20px;
}
::v-deep .el-timeline-item__timestamp.is-top {
  // position: absolute;
  // left: -120px;
  // top: -3px;
  color: #ff8041;
  font-size: 2.5rem;
  font-weight: bolder;
}
// ::v-deep .el-timeline {
//   padding-left: 150px;
//   padding-top: 30px;
//   float: left;
// }
.lichen-content a {
  cursor: pointer;
  color: #10131f;
  text-decoration-line: none;
}
.lichen-content a:hover {
  cursor: pointer;
  color: #ff8041;
  text-decoration-line: none;
}
.lichen-content a .fazhanwz {
  cursor: pointer;
  color: #10131f;
  text-decoration-line: none;
}
.lichen-content a .fazhanwz:hover {
  cursor: pointer;
  color: #ff8041;
  text-decoration-line: none;
}
.qywhzbt {
  margin-top: 2rem;
  font-weight: bolder;
  font-style: italic;
  line-height: 40px;
  font-size: 1.6rem;
  color: #333333;
}
.qywhfbt {
  font-size: 1.4rem;
  color: #999;
}
.navtb {
  font-size: 1.8rem;
  padding: 10px;
  font-weight: bolder;
}
//移动端侧边菜单栏样式调整
::v-deep .el-menu .el-menu-item {
  margin: 10px;
}
::v-deep.el-menu .el-menu-item:hover {
  outline: 0 !important;
  color: #ff8041 !important;
  background: #eeeeee;
  margin: 10px;
  border-radius: 10px;
  // background: linear-gradient(270deg, #f2f7fc 0%, #fefefe 100%) !important;
}
::v-deep.el-menu .el-menu-item.is-active {
  color: #ff8041 !important;
  background: #eeeeee;
  margin: 10px;
  border-radius: 10px;
  // background: linear-gradient(270deg, #f2f7fc 0%, #fefefe 100%) !important;
}
.footwz_mb {
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
}
</style>
