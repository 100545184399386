<template>
  <div class="timeline">
    <div
      class="timeline-container"
      :style="{ transform: 'translateX(' + position + 'px)' }"
    >
      <div v-for="(item, index) in items" :key="index" class="timeline-item">
        <div class="timeline-item-time">{{ item.time }}</div>
        <div class="timeline-item-content">{{ item.content1 }}</div>
        <div class="timeline-item-content">{{ item.content2 }}</div>
        <div class="timeline-item-content">{{ item.content3 }}</div>
      </div>
    </div>
    <div class="timeline-controls">
      <div class="button" @click="scroll(1)">
        <i class="el-icon-back"></i>
      </div>
      <div class="button" @click="scroll(-1)">
        <i class="el-icon-right"></i>
      </div>
      <!-- <button></button>
      <button></button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Timeline",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      position: 0,
      itemWidth: 250, // 时间线单元格的宽度
      containerWidth: -200, // 时间线容器的宽度
    };
  },
  mounted() {
    this.containerWidth = this.$refs.container.clientWidth;
  },
  methods: {
    // 滚动时间线
    scroll(direction) {
      const delta = direction * this.itemWidth;
      const newPosition = this.position + delta;
      if (newPosition > 0) {
        this.position = 0;
      } else if (
        newPosition <
        this.containerWidth - this.items.length * this.itemWidth
      ) {
        this.position =
          this.containerWidth - this.items.length * this.itemWidth;
      } else {
        this.position = newPosition;
      }
    },
  },
};
</script>

<style scoped>
.timeline {
  position: relative;
  overflow: hidden;
  height: 360px;
}

.timeline-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
}

.timeline-item {
  width: 300px;
  padding: 0 15px 0 0;
  /* background-color: #f1f1f1; */
  border-radius: 4px;
  /* margin-right: 20px; */
  display: flex;
  flex-direction: column;
}

.timeline-item-content {
  font-size: 1.6rem;
  line-height: 3rem;
  padding-bottom: 10px;
  color: #666666;
  /* font-weight: bold; */
}

.timeline-item-time {
  /* margin-top: 5px; */
  font-size: 2.3rem;
  color: #ff8041;
  line-height: 4rem;
}

.timeline-controls {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  text-align: center;
}

.timeline-controls .button {
  background-color: #ff8041;
  color: #fff;
  border: none;
  border-radius: 15px;
  padding: 10px 25px;
  font-size: 25px;
  margin: 15px;
  cursor: pointer;
  transition: color 0.8s ease;
}
.timeline-controls .button:hover {
  background-color: #f1f1f1;
  color: #ff8041;
  transition: color 0.8s ease;
}

.timeline-controls button:last-child {
  margin-right: 0;
}
</style>
