<template>
  <Project />
</template>

<script>
// @ is an alias to /src
import Project from '@/components/Project.vue'
export default {
  name: 'CsProject',
  components: {
    Project
  }
}
</script>