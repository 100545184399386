<template>
  <div class="container">
    <div class="pcview" v-if="pccentenshow">
      <!-- 导航栏 -->
      <el-row class="navbar navbar-default">
        <el-col :span="12">
          <el-row style="display: flex; align-items: center; margin-left: 15px">
            <img src="../assets/cslogo.png" width="430px" height="50px" />
          </el-row>
        </el-col>
        <el-col :span="12">
          <div
            class="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
            ref="navbar1"
            v-if="nvabar1show"
          >
            <el-row class="navright">
              <el-col :span="4" class="nav_item">
                <router-link to="/" class="navwz">首页</router-link>
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/CsIntroduce" class="navwz"
                  >公司介绍</router-link
                >
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/Product" class="navwz">产品领域</router-link>
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/Project" class="navwz">项目案例</router-link>
              </el-col>
              <el-col :span="5" class="nav_item">
                <router-link to="/Contact" class="navwz">联系我们</router-link>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <!-- 菜单栏 -->
      <el-row>
        <img src="../assets/about_bg.jpg" width="100%" height="100%" />
      </el-row>
      <!-- <el-row class="menubg">
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple-light"></div>
        </el-col>
        <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
          <div>
            <el-row>
              <div style="height: 186px"></div>
            </el-row>
            <el-row
              style="
                background-color: #ffffff;
                align-items: center;
                display: flex;
              "
            >
              <el-col :span="1">
                <img src="../assets/shubiao.gif" />
              </el-col>
              <el-col :span="4" style="text-align: center">
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <img src="../assets/lianxi_xtb.jpg" />
                </el-row>
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <div class="menuwz"><a href="#">联系我们</a></div>
                </el-row>
              </el-col>
              <el-col :span="4" style="text-align: center">
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <img src="../assets/renc_xtb.jpg" />
                </el-row>
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <div class="menuwz"><a href="#">人才招聘</a></div>
                </el-row>
              </el-col>
              <el-col :span="15">
                <el-row>
                  <div style="margin-top: 10px"></div>
                </el-row>
                <el-row>
                  <el-col :span="21">
                    <div class="menuwz">
                      <router-link to="/" style="float: right"
                        >返回首页</router-link
                      >
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <img src="../assets/return.jpg" />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
          <div class="grid-content bg-purple"></div>
        </el-col>
      </el-row> -->
      <!-- 内容 -->
      <el-row style="background-color: #f9f9f9">
        <!-- 标题 -->
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row>
              <el-col :span="24">
                <div style="text-align: center; margin-top: 30px">
                  <div class="bt">联系我们</div>
                  <div class="fbt">contact us</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <!-- 介绍 -->
        <el-row style="margin-top: 3%"></el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="1" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="20" :xl="16">
            <el-row style="align-items: center; display: flex" :gutter="20">
              <el-col :span="9">
                <el-row
                  type="flex"
                  style="align-items: center; padding-bottom: 10px"
                >
                  <img src="../assets/csphone.png" width="14%" height="14%" />
                  <div style="margin-left: 2%">
                    <div class="contactwz" style="font-weight: bold">
                      市场电话
                    </div>
                    <div class="contactwz">020-89884234</div>
                  </div>
                </el-row>
                <el-row
                  type="flex"
                  style="align-items: center; padding-bottom: 10px"
                >
                  <img src="../assets/lxemail.png" width="14%" height="14%" />
                  <div style="margin-left: 2%">
                    <div class="contactwz" style="font-weight: bold">
                      联系邮箱
                    </div>
                    <div class="contactwz">zhongweiming@cangshigroup.com</div>
                  </div>
                </el-row>
                <el-row
                  type="flex"
                  style="align-items: center; padding-bottom: 10px"
                >
                  <img src="../assets/ywlx.png" width="14%" height="14%" />
                  <div style="margin-left: 2%">
                    <div class="contactwz" style="font-weight: bold">
                      业务联系人
                    </div>
                    <div style="display: flex">
                      <div class="contactwz">钟生：13533410010</div>
                      <div class="contactwz" style="margin-left: 10px">
                        龚生：13560112015
                      </div>
                    </div>
                  </div>
                </el-row>
                <el-row
                  type="flex"
                  style="align-items: center; padding-bottom: 10px"
                >
                  <img src="../assets/csdizhi.png" width="14%" height="14%" />
                  <div style="margin-left: 2%">
                    <div class="contactwz" style="font-weight: bold">
                      办公地址
                    </div>
                    <div class="contactwz">
                      广州市越秀区广卫路19-2号金科大厦6楼
                    </div>
                  </div>
                </el-row>
              </el-col>
              <el-col :span="15">
                <el-row style="text-align: end">
                  <img src="../assets/csditu.png" width="100%" />
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="1" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="2">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="20">
            <el-row>
              <el-col :span="6">
                <el-row type="flex" style="align-items: center">
                  <img src="../assets/lianxi_t2.png" width="20%" height="20%" />
                  <div style="margin-left: 2%">
                    <div class="contactwz" style="font-weight: bold">
                      市场电话
                    </div>
                    <div class="contactwz">020-89884234</div>
                  </div>
                </el-row>
              </el-col>
              <el-col :span="6">
                <el-row type="flex" style="align-items: center">
                  <img src="../assets/lianxi_t3.png" width="20%" height="20%" />
                  <div style="margin-left: 2%">
                    <div class="contactwz" style="font-weight: bold">
                      联系邮箱
                    </div>
                    <div class="contactwz">zhongweiming@cangshigroup.com</div>
                  </div>
                </el-row>
              </el-col>
              <el-col :span="6">
                <el-row type="flex" style="align-items: center">
                  <img src="../assets/lianxi_t4.png" width="20%" height="20%" />
                  <div style="margin-left: 2%">
                    <div class="contactwz" style="font-weight: bold">
                      业务联系人
                    </div>
                    <div class="contactwz">钟生：13533410010</div>
                    <div class="contactwz">龚生：13560112015</div>
                  </div>
                </el-row>
              </el-col>
              <el-col :span="6">
                <el-row type="flex" style="align-items: center">
                  <img src="../assets/lianxi_t1.png" width="20%" height="20%" />
                  <div style="margin-left: 2%">
                    <div class="contactwz" style="font-weight: bold">
                      办公地址
                    </div>
                    <div class="contactwz">
                      广州市越秀区广卫路19号金科大厦6楼
                    </div>
                  </div>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="2">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row> -->
        <!-- 地图 -->
        <!-- <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row>
              <img src="../assets/ditu.jpg" width="100%" />
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row> -->
        <el-row style="margin-top: 2%"></el-row>
        <!-- 人才招聘 -->
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-row>
              <el-col :span="24">
                <div style="text-align: center; margin-top: 30px">
                  <div class="bt">人才招聘</div>
                  <div class="fbt">Talent recruitment</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 3%"></el-row>
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="16">
            <el-collapse accordion>
              <el-collapse-item>
                <template slot="title">
                  <div style="margin-left: 30px"></div>
                  <div style="font-size: 16px">市场企划文案专员</div>
                  <!-- <div style="margin-left: 300px"></div> -->
                  <!-- <div style="font-size: 16px">招聘人数：2</div> -->
                </template>
                <div style="padding: 40px">
                  <div class="rencbt">职位描述</div>
                  <div class="rencwz">
                    1、
                    围绕公司企业文化体系、策划、组织并及时做好宣传推广，协助公司各类宣传策划方案的设计和撰写；
                  </div>
                  <div class="rencwz">
                    2、 负责公司宣传推广文案及宣传资料文案的撰写；
                  </div>
                  <div class="rencwz">
                    3、 负责公司产品对外宣传推广文案、PPT的撰写；
                  </div>
                  <div class="rencwz">
                    4、
                    具备较好的文字功底和创意想象力及品味鉴赏力，执行力强、有良好的策略思考能力并能独立撰写方案；
                  </div>
                  <div class="rencwz">
                    5、 研究行业推广方案，撰写各种营销文案与实施方案；
                  </div>
                  <div class="rencwz">6、 完成上级所交代的工作；</div>
                  <div style="margin-top: 20px"></div>
                  <div class="rencbt">任职要求</div>
                  <div class="rencwz">
                    1、
                    本科及以上学历，中文、新闻传媒、营销、广告策划、公共关系等相关专业；
                  </div>
                  <div class="rencwz">
                    2、
                    熟悉操作办公软件WORD，PPT，PS等软件，有较强的逻辑思维能力；
                  </div>
                  <div class="rencwz">
                    3、 具有政府机构，IT软件推广工作经验者优先；
                  </div>
                  <div class="rencwz">
                    4、 具有1年以上企业文化或产品宣传撰写工作经验者优先；
                  </div>
                  <div class="rencwz">
                    5、 具备较强的责任心、执行力、抗压能力和团队合作精神。
                  </div>
                  <div class="rencwz">
                    6、
                    思维敏捷，洞察力强，文字功底扎实,语言表达能力强，善于主动沟通,良好的协作能力；（如有过往作品，请随简历一并提供）
                  </div>
                  <div style="margin-top: 20px"></div>
                  <div class="rencbt">联系人：刘小姐</div>
                  <div class="rencbt">联系电话：020-89884234</div>
                  <div class="rencbt">邮箱：liumeiyu@cangshigroup.com</div>
                </div>
              </el-collapse-item>
              <el-row>
                <div style="margin-top: 10px"></div>
              </el-row>
              <el-collapse-item>
                <template slot="title">
                  <div style="margin-left: 30px"></div>
                  <div style="font-size: 16px">ASP.NET软件工程师</div>
                  <!-- <div style="margin-left: 270px"></div> -->
                  <!-- <div style="font-size: 16px">招聘人数：2</div> -->
                </template>
                <div style="padding: 40px">
                  <div class="rencbt">职位描述</div>
                  <div class="rencwz">
                    1、 根据项目安排承担开发任务，并按计划完成任务目标；
                  </div>
                  <div class="rencwz">
                    2、 独立完成软件的代码编写，辅助系统的设计工作；
                  </div>
                  <div class="rencwz">
                    3、 辅助编写系统功能模块相关的技术文档；
                  </div>
                  <div style="margin-top: 20px"></div>
                  <div class="rencbt">任职要求</div>
                  <div class="rencwz">
                    1、
                    计算机、信息计算、地理信息系统、测绘等相关专业专科及以上学历；
                  </div>
                  <div class="rencwz">
                    2、 熟练掌握ASP.NET MVC、C#开发语言,1
                    年以上ASP.NET项目开发经验；
                  </div>
                  <div class="rencwz">
                    3、 熟悉JavaScript、JQuery、AJAX、Json，了解DIV+CSS布局；
                  </div>
                  <div class="rencwz">
                    4、 熟悉Oracle、SQL Server等主流大型数据库应用开发；
                  </div>
                  <div class="rencwz">5、 熟悉Asp.Net Core、WebApi者更优。</div>
                  <div class="rencwz">
                    6、
                    熟悉前端开发框架（如：AngularJS、Bootstrap、LayUI、Vue.JS）者更优；
                  </div>
                  <div class="rencwz">
                    7、 有国土或规划行业项目开发经验者优先考虑；
                  </div>
                  <div class="rencwz">8、 有移动端经验者优先考虑；</div>
                  <div style="margin-top: 20px"></div>
                  <div class="rencbt">联系人：刘小姐</div>
                  <div class="rencbt">联系电话：020-89884234</div>
                  <div class="rencbt">邮箱：liumeiyu@cangshigroup.com</div>
                </div>
              </el-collapse-item>
              <el-row>
                <div style="margin-top: 10px"></div>
              </el-row>
              <el-collapse-item>
                <template slot="title">
                  <div style="margin-left: 30px"></div>
                  <div style="font-size: 16px">网页设计师(HTML/CSS)</div>
                  <!-- <div style="margin-left: 244px"></div> -->
                  <!-- <div style="font-size: 16px">招聘人数：1</div> -->
                </template>
                <div style="padding: 40px">
                  <div class="rencbt">职位描述</div>
                  <div class="rencwz">1、 负责网站的功能开发，架构和设计；</div>
                  <div class="rencwz">2、 负责网站切图，web前/后端搭建；</div>
                  <div class="rencwz">
                    3、 前端各类动效开发，组件开发，网站性能优化，提升产品体验；
                  </div>
                  <div class="rencwz">
                    4、 根据产品需求和特性设置并完成产品前端展示效果和交互功能；
                  </div>
                  <div class="rencwz">
                    5、 网站PC端和移动端响应式适配和开发；
                  </div>
                  <div style="margin-top: 20px"></div>
                  <div class="rencbt">任职要求</div>
                  <div class="rencwz">1、 从事网页设计工作2年及以上经验；</div>
                  <div class="rencwz">
                    2、
                    有较强的美术设计和创意能力，对网站整体设计的把握能力较强；
                  </div>
                  <div class="rencwz">
                    3、
                    精通Photoshop、Dreamweaver、Illustrator等相关网页设计软件；
                  </div>
                  <div class="rencwz">
                    4、 熟练运用HTML/XHTML/DIV+CSS网页制作技术；
                  </div>
                  <div class="rencwz">
                    5、
                    熟练响应式布局，能够编写符合W3C标准、兼容多种浏览器的代码。
                  </div>
                  <div class="rencwz">
                    6、
                    有良好的沟通能力和团队协作意识，工作认真负责，责任心强，做事细心有耐心；
                  </div>
                  <div style="margin-top: 20px"></div>
                  <div class="rencbt">联系人：刘小姐</div>
                  <div class="rencbt">联系电话：020-89884234</div>
                  <div class="rencbt">邮箱：liumeiyu@cangshigroup.com</div>
                </div>
              </el-collapse-item>
              <el-row>
                <div style="margin-top: 10px"></div>
              </el-row>
              <el-collapse-item>
                <template slot="title">
                  <div style="margin-left: 30px"></div>
                  <div style="font-size: 16px">软件实施工程师</div>
                  <!-- <div style="margin-left: 303px"></div> -->
                  <!-- <div style="font-size: 16px">招聘人数：2</div> -->
                </template>
                <div style="padding: 40px">
                  <div class="rencbt">职位描述</div>
                  <div class="rencwz">
                    1、 负责服务器平台软件及数据库安装，系统部署、调试；
                  </div>
                  <div class="rencwz">
                    2、
                    负责公司软件项目现场客户沟通、实施、培训，确保项目顺利实施；
                  </div>
                  <div class="rencwz">
                    3、
                    根据项目应用需求分析以及客户沟通结果，应用公司产品进行相关业务表单、功能等配置，提供客户上线应用；
                  </div>
                  <div class="rencwz">
                    4、
                    做好客户项目需求收集和软件售后服务工作，维护与客户的关系；
                  </div>
                  <div style="margin-top: 20px"></div>
                  <div class="rencbt">任职要求</div>
                  <div class="rencwz">
                    1、 计算机或地理信息相关专业大专及以上学历；
                  </div>
                  <div class="rencwz">
                    2、
                    三年以上软件项目实施工作经验，可独立承担项目经理角色者优先；
                  </div>
                  <div class="rencwz">
                    3、 熟练Linux服务器安装维护，JAVA平台系统部署；
                  </div>
                  <div class="rencwz">
                    4、 能熟练使用Oracle、SQL SERVER等数据库软件；
                  </div>
                  <div class="rencwz">
                    5、 能熟练操作使用数据库SQL语言，有熟练的SQL脚本编写经验。
                  </div>
                  <div class="rencwz">
                    6、 有人大金仓 KingbaseES数据，ArcGIS
                    10、AutoCAD等绘图软件操作经验者优先；
                  </div>
                  <div class="rencwz">
                    7、
                    熟悉网络知识，具有较强的实际操作及网络连通性排查处理能力。熟悉计算机软硬件维护，熟悉数据库备份策略、病毒处理等。
                  </div>
                  <div class="rencwz">
                    8、
                    较强的沟通协调能力、严密的思维和良好的语言表达能力，反应敏捷，应变能力强，有快速的学习能力、接受能力和理解力。
                  </div>
                  <div style="margin-top: 20px"></div>
                  <div class="rencbt">联系人：刘小姐</div>
                  <div class="rencbt">联系电话：020-89884234</div>
                  <div class="rencbt">邮箱：liumeiyu@cangshigroup.com</div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="4">
            <div class="grid-content bg-purple"></div>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-top: 40px"></div>
        </el-row>
      </el-row>
      <div class="foot">
        <el-row>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="20">
            <el-row style="margin-top: 30px"></el-row>
            <el-row style="display: flex; align-items: center">
              <el-col :xs="4" :sm="4" :md="3" :lg="3" :xl="3">
                <img src="../assets/logo-foot.png" width="100%" />
              </el-col>
              <el-col :xs="20" :sm="20" :md="21" :lg="21" :xl="21">
                <el-row style="margin-left: 150px">
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">公司介绍</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpabout">关于仓实</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpcourse">
                          公司历程
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpyewu">核心业务</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpzizhi">公司资质</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpculture">
                          企业文化
                        </div>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">公司产品</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpgov">
                          政府信息化应用平台
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpgov">
                          政府行业技术服务
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpent">
                          企业信息化应用平台
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpent">
                          企业行业技术服务
                        </div>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">项目案例</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumphejin">合景泰富</div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpbudongchan">
                          不动产信息建设
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpshidai">
                          时代中国
                        </div>
                      </el-row>
                      <el-row>
                        <div class="aboutitem" @click="jumpkaide">凯德中国</div>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <el-row>
                        <div class="about">联系我们</div>
                      </el-row>
                      <el-row>
                        <div class="aboutline"></div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb1.png"
                          width="20px"
                          height="18px"
                        />
                        <div class="aboutitem">020-89884234</div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb2.png"
                          width="20px"
                          height="20px"
                        />
                        <div class="aboutitem">
                          zhongweiming@cangshigroup.com
                        </div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb3.png"
                          width="20px"
                          height="16px"
                        />
                        <div class="aboutitem">邮政编码:510000</div>
                      </el-row>
                      <el-row type="flex" style="align-items: center">
                        <img
                          src="../assets/foot_tb4.png"
                          width="20px"
                          height="21px"
                        />
                        <div class="aboutitem">
                          广州市越秀区广卫路19-2金科大厦6楼
                        </div>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-top: 20px"></div>
        </el-row>
        <el-row>
          <div
            style="
              text-align: center;
              color: #ccc;
              line-height: 30px;
              font-size: 14px;
              font-weight: lighter;
              border-top: 1px solid #222634;
            "
          >
            CopyRight © 2022 All Right Reserved 广州仓实信息科技有限公司
            粤ICP备07032789号
          </div>
        </el-row>
      </div>
      <back-top />
      <el-backtop
        target=".container"
        :bottom="30"
        :right="50"
        :visibility-height="400"
      >
        <div
          style="
             {
              height: 100%;
              width: 100%;
              background-color: #ff8041;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
              text-align: center;
              line-height: 40px;
              color: #ffffff;
              border-radius: 50px;
              align-items: center;
              text-align: center;
            }
          "
        >
          <i class="el-icon-caret-top"></i>
        </div>
      </el-backtop>
    </div>
    <!-- 移动端 -->
    <div class="mobileshow" v-if="mobilecentenshow">
      <el-row
        style="display: flex; align-items: center; padding: 10px 10px 10px 10px"
      >
        <el-col :span="20">
          <div @click="backhome">
            <img src="../assets/cslogo-mb.png" style="width: 100%" />
          </div>
        </el-col>
        <el-col :span="4" style="text-align: end">
          <div @click="hangdleclick = true">
            <img src="../assets/menu_b.png" style="width: 50%" />
          </div>
        </el-col>
      </el-row>

      <el-drawer
        title=""
        :visible.sync="hangdleclick"
        :direction="direction"
        size="60%"
        :overflow-y="hidden"
      >
        <el-row>
          <el-menu default-active="/Contact" router active-text-color="#ff8041">
            <el-menu-item index="/">
              <template slot="title">
                <div @click="hangdleclick = false" style="padding: 0 0 0 10px">
                  <i class="el-icon-house" style="font-weight: bolder"></i>
                  <span class="navtb">首页</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/CsIntroduce">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i
                    class="el-icon-office-building"
                    style="font-weight: bolder"
                  ></i>
                  <span class="navtb">公司介绍</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/Product">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i class="el-icon-box" style="font-weight: bolder"></i>
                  <span class="navtb">产品领域</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/Project">
              <template slot="title">
                <div style="padding: 0 0 0 10px">
                  <i
                    class="el-icon-data-analysis"
                    style="font-weight: bolder"
                  ></i>
                  <span class="navtb">项目案例</span>
                </div>
              </template>
            </el-menu-item>
            <el-menu-item index="/Contact">
              <template slot="title">
                <div
                  style="
                    padding: 0 0 0 10px;
                    background-color: #eeeeee;
                    border-radius: 10px;
                  "
                >
                  <i class="el-icon-message" style="font-weight: bolder"></i>
                  <span class="navtb">联系我们</span>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-row>
      </el-drawer>
      <el-row>
        <img src="../assets/mb_about_bg.jpg" width="100%" />
      </el-row>
      <!-- <el-row style="text-align: center">
        <el-col :span="6">
          <div><a href="#" class="abwz">联系我们</a></div>
        </el-col>
        <el-col :span="6">
          <div><a href="#" class="abwz">人才招聘</a></div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-light"></div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-light"></div>
        </el-col>
      </el-row> -->
      <!-- 移动端联系我们 -->
      <el-row style="background: #f7f7f7">
        <el-row>
          <el-row>
            <el-col :span="24">
              <div class="tltle">
                <p class="ntz">联系我们</p>
                <p class="nxd">contact us</p>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5%"></el-row>
          <el-row>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
              <img src="../assets/csditu_mb.png" width="100%" />
            </el-col>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
              <el-row style="margin-top: 5%">
                <el-row>
                  <div class="lianxibt">市场电话</div>
                  <div class="lianxiwz">020-89884234</div>
                </el-row>
                <el-row style="margin-top: 10px"></el-row>
                <el-row>
                  <div class="lianxibt">联系邮箱</div>
                  <div class="lianxiwz">zhongweiming@cangshigroup.com</div>
                </el-row>
                <el-row style="margin-top: 10px"></el-row>
                <el-row>
                  <div class="lianxibt">业务联系人</div>
                  <div class="lianxiwz" style="display: flex">
                    <div>钟生：13533410010</div>
                    <div style="margin-left: 15px">龚生：13560112015</div>
                  </div>
                </el-row>
                <el-row style="margin-top: 10px"></el-row>
                <el-row>
                  <div class="lianxibt">办公地址</div>
                  <div class="lianxiwz">
                    广州市越秀区广卫路19-2号金科大厦6楼
                  </div>
                </el-row>
              </el-row>
            </el-col>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5%"></el-row>
        </el-row>
      </el-row>
      <el-row>
        <el-row style="background: #f7f7f7">
          <el-row>
            <el-col :span="24">
              <div class="tltle">
                <p class="ntz">人才招聘</p>
                <p class="nxd">talent recruitment</p>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 5%"></el-row>
          <el-row>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :xs="22" :sm="22" :md="20" :lg="16" :xl="16">
              <el-collapse accordion>
                <el-collapse-item>
                  <template slot="title">
                    <div style="font-size: 14px; margin-left: 10px">
                      市场企划文案专员
                    </div>
                    <!-- <div style="margin-left: 100px; font-size: 14px">
                      招聘人数：2
                    </div> -->
                  </template>
                  <div style="padding: 40px">
                    <div class="nrencbt">职位描述</div>
                    <div class="nrencwz">
                      1、
                      围绕公司企业文化体系、策划、组织并及时做好宣传推广，协助公司各类宣传策划方案的设计和撰写；
                    </div>
                    <div class="nrencwz">
                      2、 负责公司宣传推广文案及宣传资料文案的撰写；
                    </div>
                    <div class="nrencwz">
                      3、 负责公司产品对外宣传推广文案、PPT的撰写；
                    </div>
                    <div class="nrencwz">
                      4、
                      具备较好的文字功底和创意想象力及品味鉴赏力，执行力强、有良好的策略思考能力并能独立撰写方案；
                    </div>
                    <div class="nrencwz">
                      5、 研究行业推广方案，撰写各种营销文案与实施方案；
                    </div>
                    <div class="nrencwz">6、 完成上级所交代的工作；</div>
                    <div style="margin-top: 20px"></div>
                    <div class="nrencbt">任职要求</div>
                    <div class="nrencwz">
                      1、
                      本科及以上学历，中文、新闻传媒、营销、广告策划、公共关系等相关专业；
                    </div>
                    <div class="nrencwz">
                      2、
                      熟悉操作办公软件WORD，PPT，PS等软件，有较强的逻辑思维能力；
                    </div>
                    <div class="nrencwz">
                      3、 具有政府机构，IT软件推广工作经验者优先；
                    </div>
                    <div class="nrencwz">
                      4、 具有1年以上企业文化或产品宣传撰写工作经验者优先；
                    </div>
                    <div class="nrencwz">
                      5、 具备较强的责任心、执行力、抗压能力和团队合作精神。
                    </div>
                    <div class="nrencwz">
                      6、
                      思维敏捷，洞察力强，文字功底扎实,语言表达能力强，善于主动沟通,良好的协作能力；（如有过往作品，请随简历一并提供）
                    </div>
                    <div style="margin-top: 20px"></div>
                    <div class="nrencbt">联系人：刘小姐</div>
                    <div class="nrencbt">联系电话：020-89884234</div>
                    <div class="nrencbt">邮箱：liumeiyu@cangshigroup.com</div>
                  </div>
                </el-collapse-item>
                <div style="margin-top: 10px"></div>
                <el-collapse-item>
                  <template slot="title">
                    <div style="font-size: 14px; margin-left: 10px">
                      ASP.NET软件工程师
                    </div>
                    <!-- <div style="margin-left: 74px; font-size: 14px">
                      招聘人数：2
                    </div> -->
                  </template>
                  <div style="padding: 40px">
                    <div class="nrencbt">职位描述</div>
                    <div class="nrencwz">
                      1、 根据项目安排承担开发任务，并按计划完成任务目标；
                    </div>
                    <div class="nrencwz">
                      2、 独立完成软件的代码编写，辅助系统的设计工作；
                    </div>
                    <div class="nrencwz">
                      3、 辅助编写系统功能模块相关的技术文档；
                    </div>
                    <div style="margin-top: 20px"></div>
                    <div class="nrencbt">任职要求</div>
                    <div class="nrencwz">
                      1、
                      计算机、信息计算、地理信息系统、测绘等相关专业专科及以上学历；
                    </div>
                    <div class="nrencwz">
                      2、 熟练掌握ASP.NET MVC、C#开发语言,1
                      年以上ASP.NET项目开发经验；
                    </div>
                    <div class="nrencwz">
                      3、 熟悉JavaScript、JQuery、AJAX、Json，了解DIV+CSS布局；
                    </div>
                    <div class="nrencwz">
                      4、 熟悉Oracle、SQL Server等主流大型数据库应用开发；
                    </div>
                    <div class="nrencwz">
                      5、 熟悉Asp.Net Core、WebApi者更优。
                    </div>
                    <div class="nrencwz">
                      6、
                      熟悉前端开发框架（如：AngularJS、Bootstrap、LayUI、Vue.JS）者更优；
                    </div>
                    <div class="nrencwz">
                      7、 有国土或规划行业项目开发经验者优先考虑；
                    </div>
                    <div class="nrencwz">8、 有移动端经验者优先考虑；</div>
                    <div style="margin-top: 20px"></div>
                    <div class="nrencbt">联系人：刘小姐</div>
                    <div class="nrencbt">联系电话：020-89884234</div>
                    <div class="nrencbt">邮箱：liumeiyu@cangshigroup.com</div>
                  </div>
                </el-collapse-item>
                <div style="margin-top: 10px"></div>
                <el-collapse-item>
                  <template slot="title">
                    <div style="font-size: 14px; margin-left: 10px">
                      网页设计师(HTML/CSS)
                    </div>
                    <!-- <div style="margin-left: 52px; font-size: 14px">
                      招聘人数：1
                    </div> -->
                  </template>
                  <div style="padding: 40px">
                    <div class="nrencbt">职位描述</div>
                    <div class="nrencwz">
                      1、 负责网站的功能开发，架构和设计；
                    </div>
                    <div class="nrencwz">
                      2、 负责网站切图，web前/后端搭建；
                    </div>
                    <div class="nrencwz">
                      3、
                      前端各类动效开发，组件开发，网站性能优化，提升产品体验；
                    </div>
                    <div class="nrencwz">
                      4、
                      根据产品需求和特性设置并完成产品前端展示效果和交互功能；
                    </div>
                    <div class="nrencwz">
                      5、 网站PC端和移动端响应式适配和开发；
                    </div>
                    <div style="margin-top: 20px"></div>
                    <div class="nrencbt">任职要求</div>
                    <div class="nrencwz">
                      1、 从事网页设计工作2年及以上经验；
                    </div>
                    <div class="nrencwz">
                      2、
                      有较强的美术设计和创意能力，对网站整体设计的把握能力较强；
                    </div>
                    <div class="nrencwz">
                      3、
                      精通Photoshop、Dreamweaver、Illustrator等相关网页设计软件；
                    </div>
                    <div class="nrencwz">
                      4、 熟练运用HTML/XHTML/DIV+CSS网页制作技术；
                    </div>
                    <div class="nrencwz">
                      5、
                      熟练响应式布局，能够编写符合W3C标准、兼容多种浏览器的代码。
                    </div>
                    <div class="nrencwz">
                      6、
                      有良好的沟通能力和团队协作意识，工作认真负责，责任心强，做事细心有耐心；
                    </div>
                    <div style="margin-top: 20px"></div>
                    <div class="nrencbt">联系人：刘小姐</div>
                    <div class="nrencbt">联系电话：020-89884234</div>
                    <div class="nrencbt">邮箱：liumeiyu@cangshigroup.com</div>
                  </div>
                </el-collapse-item>
                <div style="margin-top: 10px"></div>
                <el-collapse-item>
                  <template slot="title">
                    <div style="font-size: 14px; margin-left: 10px">
                      软件实施工程师
                    </div>
                    <!-- <div style="margin-left: 103px; font-size: 14px">
                      招聘人数：2
                    </div> -->
                  </template>
                  <div style="padding: 40px">
                    <div class="nrencbt">职位描述</div>
                    <div class="nrencwz">
                      1、 负责服务器平台软件及数据库安装，系统部署、调试；
                    </div>
                    <div class="nrencwz">
                      2、
                      负责公司软件项目现场客户沟通、实施、培训，确保项目顺利实施；
                    </div>
                    <div class="nrencwz">
                      3、
                      根据项目应用需求分析以及客户沟通结果，应用公司产品进行相关业务表单、功能等配置，提供客户上线应用；
                    </div>
                    <div class="nrencwz">
                      4、
                      做好客户项目需求收集和软件售后服务工作，维护与客户的关系；
                    </div>
                    <div style="margin-top: 20px"></div>
                    <div class="nrencbt">任职要求</div>
                    <div class="nrencwz">
                      1、 计算机或地理信息相关专业大专及以上学历；
                    </div>
                    <div class="nrencwz">
                      2、
                      三年以上软件项目实施工作经验，可独立承担项目经理角色者优先；
                    </div>
                    <div class="nrencwz">
                      3、 熟练Linux服务器安装维护，JAVA平台系统部署；
                    </div>
                    <div class="nrencwz">
                      4、 能熟练使用Oracle、SQL SERVER等数据库软件；
                    </div>
                    <div class="nrencwz">
                      5、 能熟练操作使用数据库SQL语言，有熟练的SQL脚本编写经验。
                    </div>
                    <div class="nrencwz">
                      6、 有人大金仓 KingbaseES数据，ArcGIS
                      10、AutoCAD等绘图软件操作经验者优先；
                    </div>
                    <div class="nrencwz">
                      7、
                      熟悉网络知识，具有较强的实际操作及网络连通性排查处理能力。熟悉计算机软硬件维护，熟悉数据库备份策略、病毒处理等。
                    </div>
                    <div class="nrencwz">
                      8、
                      较强的沟通协调能力、严密的思维和良好的语言表达能力，反应敏捷，应变能力强，有快速的学习能力、接受能力和理解力。
                    </div>
                    <div style="margin-top: 20px"></div>
                    <div class="nrencbt">联系人：刘小姐</div>
                    <div class="nrencbt">联系电话：020-89884234</div>
                    <div class="nrencbt">邮箱：liumeiyu@cangshigroup.com</div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-col>
            <el-col :xs="1" :sm="1" :md="2" :lg="4" :xl="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10%"></el-row>
        </el-row>
      </el-row>
      <!-- 移动端底部 -->
      <div style="background-color: #1b2033">
        <el-row>
          <el-col :xs="2" :sm="2" :md="4" :lg="4" :xl="5">
            <div class="grid-content bg-purple"></div>
          </el-col>
          <el-col :xs="20" :sm="20" :md="16" :lg="16" :xl="14">
            <el-row style="margin-top: 30px">
              <img src="../assets/logo-footmb.png" width="50%" />
            </el-row>
            <el-row style="margin-top: 30px">
              <el-col :span="10">
                <router-link to="/CsIntroduce" class="footwz_mb"
                  >关于仓实</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
              <el-col :span="14">
                <router-link to="/Product" class="footwz_mb"
                  >公司产品</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 20px">
              <el-col :span="10">
                <router-link to="/Project" class="footwz_mb"
                  >项目案例</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
              <el-col :span="14">
                <router-link to="/Contact" class="footwz_mb"
                  >联系我们</router-link
                >
                <div
                  style="
                    border-bottom: 2px solid #ff8044;
                    width: 15px;
                    margin-top: 4px;
                  "
                ></div>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row
              style="color: #a6a7a9; font-size: 12px; font-weight: lighter"
            >
              <el-row> CopyRight © 2022 All Right Reserved </el-row>
              <el-row> 广州仓实信息科技有限公司 </el-row>
              <el-row> 粤ICP备07032789号 </el-row>
            </el-row>
            <!-- <el-row type="flex" justify="center" class="footnav">
              <el-col :span="6" style="border-right: 1px solid #a6a7a9">
                <router-link to="/">首页</router-link>
              </el-col>
              <el-col :span="6" style="border-right: 1px solid #a6a7a9">
                <router-link to="/CsIntroduce">公司介绍</router-link>
              </el-col>
              <el-col :span="6" style="border-right: 1px solid #a6a7a9">
                <router-link to="/Project">项目案例</router-link>
              </el-col>
              <el-col :span="6">
                <router-link to="/Contact">联系我们</router-link>
              </el-col>
            </el-row> -->
            <el-row>
              <div style="margin-top: 3%"></div>
            </el-row>
          </el-col>
          <el-col :xs="2" :sm="2" :md="4" :lg="4" :xl="5">
            <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>

        <!-- <el-row justify="center" type="flex">
          <el-col :span="5">
            <div style="width: 20px"></div>
          </el-col>
          <el-col :span="14">
            <div class="banqz">
              CopyRight 2022 All Right Reserved <br />广州仓实信息科技有限公司
              粤ICP备07032789号
            </div>
          </el-col>
          <el-col :span="5">
            <div style="width: 20px"></div>
          </el-col>
        </el-row> -->
        <el-row>
          <div style="margin-bottom: 3%"></div>
        </el-row>
      </div>
      <el-backtop
        target=".container"
        :bottom="15"
        :right="15"
        :visibility-height="30"
      >
        <div
          style="
             {
              height: 100%;
              width: 100%;
              background-color: #ff8041;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
              text-align: center;
              line-height: 40px;
              color: #ffffff;
              border-radius: 50px;
              align-items: center;
              text-align: center;
            }
          "
        >
          <i class="el-icon-caret-top"></i>
        </div>
      </el-backtop>
    </div>
  </div>
</template>

<script>
import backTop from "@/components/backTop.vue";
export default {
  components: { backTop },
  data() {
    return {
      screenWidth: 0,
      screenHeight: 0,
      nvabar1show: true,
      nvabar2show: false,
      pccentenshow: true,
      mobilecentenshow: false,
      mobileshow: true,
      hangdleclick: false,
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; // 实时宽度
        window.screenHeight = document.documentElement.clientHeight; // 实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
        that.hangdlenvabarshow();
        that.hangdlepsshow();
      })();
    };
  },
  created() {
    window.screenWidth = document.documentElement.clientWidth; // 实时宽度
    window.screenHeight = document.documentElement.clientHeight; // 实时高度
    this.screenWidth = window.screenWidth;
    this.screenHeight = window.screenHeight;
    this.hangdlenvabarshow();
    this.hangdlepsshow();
  },
  methods: {
    hangdlenvabarshow() {
      if (this.screenWidth > 1080) {
        this.nvabar1show = true;
        // this.$refs.navbar1.style.display = 'none'
        // this.nvabar2show = true;
      } else {
        if (this.screenWidth < 1080) {
          this.nvabar1show = false;
        }
        // if (this.screenWidth >= 979) {
        //   this.nvabar1show = true;
        //   this.nvabar2show = false;
        // }
      }
    },
    hangdlepsshow() {
      if (this.screenWidth > 1080) {
        this.pccentenshow = true;
        this.mobilecentenshow = false;
      } else {
        this.pccentenshow = false;
        this.mobilecentenshow = true;
      }
    },
    backhome() {
      this.$router.push("/");
    },
    jumpabout() {
      localStorage.setItem("cec", "aboutcs");
      this.$router.push({ path: "/CsIntroduce" });
    },
    jumpcourse() {
      localStorage.setItem("cec", "course");
      this.$router.push({ path: "/CsIntroduce" });
    },
    jumpculture() {
      localStorage.setItem("cec", "culture");
      this.$router.push({ path: "/CsIntroduce" });
    },
    jumpyewu() {
      localStorage.setItem("cec1", "yewu");
      this.$router.push({ path: "/" });
    },
    jumpzizhi() {
      localStorage.setItem("cec1", "zizhi");
      this.$router.push({ path: "/" });
    },
    jumpgov() {
      localStorage.setItem("cec2", "gov");
      this.$router.push({ path: "/Product" });
    },
    jumpent() {
      localStorage.setItem("cec2", "ent");
      this.$router.push({ path: "/Product" });
    },

    jumphejin() {
      localStorage.setItem("cec3", "hejin");
      this.$router.push({ path: "/Project" });
    },
    jumpbudongchan() {
      localStorage.setItem("cec3", "budongchan");
      this.$router.push({ path: "/Project" });
    },
    jumpshidai() {
      localStorage.setItem("cec3", "shidai");
      this.$router.push({ path: "/Project" });
    },
    jumpkaide() {
      localStorage.setItem("cec3", "kaide");
      this.$router.push({ path: "/Project" });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding: 0;
  height: 100%;
  // overflow-y: auto;
  overflow-x: hidden;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  border: 0px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 0 13px 0 rgb(0 0 0 / 14%);
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 0;
}
.navbar-header {
  align-items: center;
  display: flex;
}
.navbar-nav {
  font-size: 18px;
  padding: 10px;
}
// 头部导航栏
.nbtzx {
  height: 41px;
  border-left: #888888 1px solid;
  float: left;
  padding-left: 10px;
}

.navwz {
  position: relative;
  box-sizing: border-box;
}

.navwz::after {
  content: "";
  width: 0;
  height: 3px;
  background: #ff8041;
  position: absolute;
  left: 50%;
  transition: all 0.4s;
  bottom: -10px;
}

.navwz:hover::after {
  left: 0;
  width: 100%;
  bottom: -10px;
}

.xl {
  position: relative;
  box-sizing: border-box;
}

.xl::after {
  content: "";
  width: 0;
  height: 3px;
  background: #ff8041;
  position: absolute;
  left: 50%;
  transition: all 0.4s;
}

.xl:hover::after {
  left: 0;
  width: 100%;
}

.navwz {
  color: #0381ca;
  text-decoration: none;
  font-weight: bolder;
}

.navwz:hover {
  color: #ff8041;
  text-decoration: none;
  font-weight: bolder;
}

.navleft {
  padding: 20px 0 20px 20px;
  align-items: center;
  display: flex;
}

.navright {
  padding: 20px 20px 20px 0;
  text-align: center;
  font-size: 20px;
  display: flex;
}

// 菜单栏
.menubg {
  background: url("../assets/about_bg.jpg") no-repeat center;
}

.menuwz a {
  font-size: 18px;
  color: black;
  text-decoration: none;
}

.menuwz a:hover {
  color: #ff8041;
  text-decoration: none;
}

.nbtzx1 {
  height: 80px;
  border-left: #ff8041 5px solid;
  float: left;
  padding-left: 10px;
}

.nbt {
  font-size: 40px;
  line-height: 45px;
  font-weight: bold;
  padding-bottom: 0px;
}

.nfbt {
  float: left;
  line-height: 25px;
  color: #999;
  text-transform: uppercase;
  padding-top: 5px;
  font-size: 16px;
}

.rencbt {
  font-size: 20px;
  font-weight: bold;
}

.rencwz {
  font-size: 16px;
}

.foot {
  // width: 1920px;
  // height: 235px;
  background: #1b2033;
  margin-top: 0px;
  clear: both;
}

.about {
  // margin-top: 15%;
  clear: both;
  line-height: 35px;
  color: #ccc;
  font-size: 23px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}

.about::after {
  content: "";
  width: 0px;
  height: 3px;
  background: #ff8041;
  position: absolute;
  left: 0;
  transition: all 0.4s;
  bottom: -5px;
}

.about:hover::after {
  left: -1;
  width: 15%;
  bottom: -5px;
}

.aboutline {
  width: 35px;
  height: 5px;
  // border-bottom: 3px #ff8041 solid;
  margin-bottom: 10px;
}

.aboutitem {
  clear: both;
  line-height: 35px;
  color: #ccc;
  font-size: 15px;
  white-space: nowrap;
  margin-left: 2%;
  cursor: pointer;
  transition: color 0.4s ease;
  text-decoration: none;
}
.aboutitem:hover {
  color: #ff8041;
  // transform: translateX(-5px);
}
// 移动端
.abwz {
  color: #333;
  text-align: center;
  line-height: 35px;
}

.ntz {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 2.2rem;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
}

.nxd {
  width: 100%;
  text-align: center;
  color: #999;
  font-size: 1.4rem;
  width: 100%;
  // margin-top: 5px;
  text-transform: uppercase;
}
.lianxibt {
  font-size: 1.6rem;
  line-height: 25px;
}
.lianxiwz {
  line-height: 20px;
  font-size: 1.5rem;
}
.nrencbt {
  font-weight: bold;
  font-size: 18px;
}
.nrencwz {
  font-size: 16px;
}
.footnav {
  text-align: center;
}

.footnav a {
  font-size: 14px;
  color: #fff;
}

.banqz {
  width: 100%;
  text-align: center;
  color: #a6a7a9;
  font-size: 12px;
  background-color: #10131f;
}

.bt {
  font-size: 35px;
  line-height: 45px;
  // font-weight: bold;
  padding-bottom: 0px;
}

.fbt {
  // float: left;
  line-height: 25px;
  // color: #999;
  text-transform: uppercase;
  padding-top: 5px;
  font-size: 20px;
}
.contactwz {
  font-size: 18px;
  line-height: 30px;
  white-space: nowrap;
}
.navtb {
  font-size: 1.8rem;
  padding: 10px;
  font-weight: bolder;
}
//移动端侧边菜单栏样式调整
::v-deep .el-menu .el-menu-item {
  margin: 10px;
}
::v-deep.el-menu .el-menu-item:hover {
  outline: 0 !important;
  color: #ff8041 !important;
  background: #eeeeee;
  margin: 10px;
  border-radius: 10px;
  // background: linear-gradient(270deg, #f2f7fc 0%, #fefefe 100%) !important;
}
::v-deep.el-menu .el-menu-item.is-active {
  color: #ff8041 !important;
  background: #eeeeee;
  margin: 10px;
  border-radius: 10px;
  // background: linear-gradient(270deg, #f2f7fc 0%, #fefefe 100%) !important;
}
.footwz_mb {
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
}
</style>
