<template>
  <div class="container">
    <el-row>
      <el-tabs :tab-position="tabPosition" v-model="activeName">
        <el-tab-pane label="软件开发建设项目" name="1"></el-tab-pane>
        <el-tab-pane label="信息化咨询设计项目" name="2"> </el-tab-pane>
        <el-tab-pane label="数据分析整理项目" name="3"> </el-tab-pane>
        <el-row v-if="activeName === '1'">
          <el-card
            v-for="(item, index) in softwareprojectList"
            :key="index"
            style="margin-bottom: 20px"
          >
            <el-row :gutter="20">
              <el-col :span="6">
                <el-image style="width: 100%" :src="item.img"></el-image>
              </el-col>
              <el-col :span="18">
                <div>
                  <div
                    style="font-size: 25px; font-weight: bolder"
                    :id="item.id"
                  >
                    {{ item.projectname }}
                  </div>
                  <div style="font-size: 18px; margin-top: 10px">
                    {{ item.projectyear }}
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-row>
        <el-row v-if="activeName === '2'">
          <el-card
            v-for="(item, index) in informationizeprojectList"
            :key="index"
            style="margin-bottom: 20px"
          >
            <el-row :gutter="20">
              <el-col :span="6">
                <el-image style="width: 100%" :src="item.img"></el-image>
              </el-col>
              <el-col :span="18">
                <div>
                  <div
                    style="font-size: 25px; font-weight: bolder"
                    :id="item.id"
                  >
                    {{ item.projectname }}
                  </div>
                  <div style="font-size: 18px; margin-top: 10px">
                    {{ item.projectyear }}
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-row>
        <el-row v-if="activeName === '3'">
          <el-card
            v-for="(item, index) in dataprojectList"
            :key="index"
            style="margin-bottom: 20px"
          >
            <el-row :gutter="20">
              <el-col :span="6">
                <el-image style="width: 100%" :src="item.img"></el-image>
              </el-col>
              <el-col :span="18">
                <div>
                  <div
                    style="font-size: 25px; font-weight: bolder"
                    :id="item.id"
                  >
                    {{ item.projectname }}
                  </div>
                  <div style="font-size: 18px; margin-top: 10px">
                    {{ item.projectyear }}
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-row>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "project-tabs",
  data() {
    return {
      tabPosition: "right",
      activeName: "1",
      softwareprojectList: [
        {
          projectname:
            "中国工商银行佛山分行与南海区大沥镇城建和水利办公室共同开发“征拆信息数据化管理平台”服务项目",
          projectyear: "2022年",
          img: require("../assets/anli_16.png"),
        },
        {
          projectname: "合生征拆管理系统建设项目",
          projectyear: "2022年",
          img: require("../assets/anli_15.png"),
        },
        {
          projectname:
            "广州市建设工程拆迁有限公司城市更新项目信息管理平台建设项目",
          projectyear: "2022年",
          img: require("../assets/anli_17.png"),
        },
        {
          projectname: "广州市不动产登记平台建设项目",
          projectyear: "2021年",
          img: require("../assets/anli_5.jpg"),
          id: "budongchan",
        },
        {
          projectname: "时代中国项目主数据系统开发项目",
          projectyear: "2021年",
          img: require("../assets/anli_4.jpg"),
        },
        {
          projectname: "星河湾城市更新系统建设及实施项目",
          projectyear: "2021年",
          img: require("../assets/anli_14.png"),
        },
        {
          projectname: "盛高城市更新系统销售及服务项目",
          projectyear: "2021年",
          img: require("../assets/anli_12.png"),
        },
        {
          projectname: "富力集团城更云平台项目",
          projectyear: "2021年",
          img: require("../assets/anli_11.png"),
        },
        {
          projectname:
            "广州机械科学研究院及其周边低效用地全面改造升级城市更新业务信息管理系统",
          projectyear: "2021年",
          img: require("../assets/anli_10.png"),
        },
        {
          projectname: "知识城拆迁系统项目",
          projectyear: "2020年",
          img: require("../assets/anli_3.jpg"),
        },
        {
          projectname: "智能人机交互建设技术服务项目",
          projectyear: "2020年",
          img: require("../assets/anli_9.png"),
        },
        {
          projectname: "时代中国城市更新拆迁信息管理系统使用技术服务项目",
          projectyear: "2020年",
          img: require("../assets/anli_4.jpg"),
        },
        {
          projectname: "合景泰富城市更新系统建设及实施项目",
          projectyear: "2020年",
          img: require("../assets/anli_1.jpg"),
          id: "hejin",
        },
        {
          projectname: "广州市土地林海登记系统开发项目",
          projectyear: "2019年",
          img: require("../assets/anli_8.png"),
        },
        {
          projectname: "时代中国城市更新拆迁信息管理系统开发项目",
          projectyear: "2019年",
          img: require("../assets/anli_4.jpg"),
          id: "shidai",
        },
        {
          projectname: "凯德中国房屋征收辅助决策分析系统开发项目",
          projectyear: "2018年",
          img: require("../assets/anli_6.jpg"),
          id: "kaide",
        },
        {
          projectname: "广东保顺检测鉴定有限公司房屋安全检测软件开发项目",
          projectyear: "2018年",
          img: require("../assets/anli7.png"),
          id: "",
        },
      ],
      informationizeprojectList: [
        {
          projectname: "黄埔区萝峰旧村改造测算咨询服务项目",
          projectyear: "2022年",
          img: require("../assets/anli_21.png"),
        },
        {
          projectname: "黄埔区萝峰村改造项目产权注销咨询技术服务合同",
          projectyear: "2021年",
          img: require("../assets/anli_21.png"),
        },
        {
          projectname: "凯德坦尾基础数据处理统筹咨询技术服务",
          projectyear: "2020年",
          img: require("../assets/anli_6.jpg"),
        },
        {
          projectname: "房屋安全技术培咨询项目",
          projectyear: "2020年",
          img: require("../assets/anli_20.png"),
        },
        {
          projectname: "不动产登记业务梳理咨询服务项目 ",
          projectyear: "2019年",
          img: require("../assets/anli_19.png"),
        },
        {
          projectname: "不动产土地林海登记业务研究咨询服务项目",
          projectyear: "2018年",
          img: require("../assets/anli_19.png"),
        },
        {
          projectname:
            "不动产统一登记机制保障业务—广州市白云区农村不动产权籍调查示范点咨询服务项目",
          projectyear: "2018年",
          img: require("../assets/anli_19.png"),
        },
        {
          projectname:
            "广州市城建安置房管理系统空间信息可视化应用咨询技术服务合同",
          projectyear: "2018年",
          img: require("../assets/anli_18.png"),
        },
      ],
      dataprojectList: [
        {
          projectname: "番禺区不动产登记空间数据整合成果融合入库咨询服务项目",
          projectyear: "2022年",
          img: require("../assets/anli_25.png"),
        },
        {
          projectname: "增城区不动产登记空间数据整合成果融合入库技术服务项目",
          projectyear: "2022年",
          img: require("../assets/anli_24.png"),
        },
        {
          projectname:
            "番禺区不动产空间数据户重落幢成果融合更新入库技术服务项目",
          projectyear: "2021年",
          img: require("../assets/anli_25.png"),
        },
        {
          projectname: "广州市中心六区不动产数据整理项目",
          projectyear: "2020年",
          img: require("../assets/anli_26.png"),
        },
        {
          projectname: "南沙区不动产登记空间数据整合成果融合入库技术服务项目",
          projectyear: "2019年",
          img: require("../assets/anli_23.png"),
        },
        {
          projectname:
            "佛山市南海区兴贤风梅岭村级工业园“工改工”城市更新改造过程技术服务项目",
          projectyear: "2019年",
          img: require("../assets/anli_22.png"),
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep.el-tabs .el-tabs__item.is-active {
  color: #ff8041 !important;
  font-size: 18px;
}
::v-deep.el-tabs .el-tabs__item:hover {
  color: #ff8041;
  transition: color 0.4s ease;
}
::v-deep.el-tabs .el-tabs__active-bar.is-right {
  background: #ff8041 !important;
}
</style>
