import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import CompanyProfile from "../views/CsIntroduce.vue";
import Product from "../views/CsProduct.vue";
import Project from "../views/CsProject";
// import Partner from "../views/CsPartner";
import Contact from "../views/CsContact";
// import Details from "../views/CsDetails";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/CsIntroduce",
    name: "CsIntroduce",
    component: CompanyProfile,
  },
  {
    path: "/Product",
    name: "CsProduct",
    component: Product,
  },
  {
    path: "/Project",
    name: "CsProject",
    component: Project,
  },
  // {
  //   path: '/Partner',
  //   name: 'CsPartner',
  //   component: Partner
  // },
  {
    path: "/Contact",
    name: "CsContact",
    component: Contact,
  },
  // {
  //   path: '/Details',
  //   name: 'CsDetails',
  //   component: Details
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   route level code-splitting
  //   this generates a separate chunk (about.[hash].js) for this route
  //   which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: "/Homepage",
    name: "homePage",
    component: () => import("../views/Homepage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
